import {
  isValid,
  parse,
  parseISO,
  startOfWeek,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { format, toZonedTime, fromZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale/en-US';

type UtcType = {
  date: Date;
  timezone: string;
};

type GetDateFromApiOptions = {
  date: string;
  convertToLocalTimezone?: boolean;
  useTime?: boolean;
  timeZone?: string | null;
};

type FormatDateOptions = {
  formatStr?: string;
  convertToLocalTimezone?: boolean;
  useTime?: boolean;
  timeZone?: string;
};

export function getLocalTimeZoneId() {
  return Intl.DateTimeFormat(enUS.code).resolvedOptions().timeZone;
}

export function getUtcDateFromTimezone({ date, timezone }: UtcType) {
  if (!date || !isValid(date) || !timezone) {
    return date;
  }
  return fromZonedTime(date, timezone);
}

export function getDateFromApiResponse({
  date,
  convertToLocalTimezone = true,
  useTime,
  timeZone,
}: GetDateFromApiOptions) {
  if (!date) return null;
  let dateValue: Date | undefined;
  if (timeZone) {
    dateValue = toZonedTime(date, timeZone);
  } else {
    if (useTime) {
      dateValue = toZonedTime(date, getLocalTimeZoneId());
    } else if (convertToLocalTimezone) {
      // Check if the string is in ISO format
      if (date.includes('T')) {
        // Parse ISO date and convert to current timezone
        dateValue = parseISO(date);
      } else {
        // Parse standard date string and convert to current timezone
        dateValue = parse(date, 'yyyy-MM-dd', new Date());
      }
    } else {
      const parsed = parseISO(date);
      if (isValid(parsed)) {
        dateValue = toZonedTime(parsed, 'UTC');
      }
    }
  }
  if (isValid(dateValue)) {
    return dateValue;
  }
  return null;
}

export function formatDate(
  date?: Date | string | null,
  options?: FormatDateOptions,
): string | null {
  const {
    formatStr = 'MM/dd/yyyy',
    convertToLocalTimezone = true,
    useTime = false,
    timeZone,
  } = options || {};
  if (!date) {
    return null;
  }
  const dateValue =
    date instanceof Date
      ? date
      : getDateFromApiResponse({
          date,
          convertToLocalTimezone,
          useTime,
          timeZone,
        });

  if (!dateValue) return '';
  return format(dateValue, formatStr, {
    locale: enUS,
    timeZone: timeZone ?? undefined,
  });
}

export function getFormattedTimezoneName(
  timezoneId: string,
  type?:
    | 'short'
    | 'long'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric',
) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezoneId,
    timeZoneName: type,
  };
  const formatter = new Intl.DateTimeFormat('en', options);
  const parts = formatter.formatToParts(new Date());
  const timezonePart = parts.find((part) => part.type === 'timeZoneName');
  return timezonePart ? timezonePart.value : '';
}

export function getDateFromTimeString(timeString?: string | null): Date | null {
  if (!timeString) {
    return null;
  }
  const dateString = '1970-01-01'; // a random date
  const combinedDateTimeString = `${dateString} ${timeString}`;
  return parse(combinedDateTimeString, 'yyyy-MM-dd HH:mm:ss', new Date());
}

export function getDateRanges() {
  const currentDate = new Date();

  // Get the first day of the current week
  const firstDayOfWeek = startOfWeek(currentDate);

  return {
    today: {
      from: format(currentDate, 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    yesterday: {
      from: format(subDays(currentDate, 1), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    currentMonth: {
      from: format(currentDate, 'yyyy-MM-01'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    lastTwoWeeks: {
      from: format(subDays(currentDate, 14), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    lastWeek: {
      from: format(subDays(currentDate, 7), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    twoMonthsAgo: {
      from: format(subMonths(currentDate, 2), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastThreeMonths: {
      from: format(subMonths(currentDate, 3), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastSixMonths: {
      from: format(subMonths(currentDate, 6), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastYear: {
      from: format(subYears(currentDate, 1), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastMonth: {
      from: format(subMonths(currentDate, 1), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    currentWeek: {
      from: format(firstDayOfWeek, 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
  };
}

import { authGet } from '../../lib';
import { showError } from '../features';
import { addResource } from '../asyncActions';
import { AppThunk, NormalizeReturn } from '../types';
import {
  BasicAssignedTransferJobsIdNamePair,
  EditInventoryItemRequest,
  InventoryItemDetailsFilterParams,
  InventoryLocationItemDetailsResponse,
  InventoryTransferItemsStateResponse,
  InventoryTransferJobAPI,
  InventoryTransferJobRequest,
  InventoryTransferTypes,
  PauseInventoryTransferJobRequest,
  ScanInventoryTransferItemRequest,
  ScanInventoryTransferItemResponse,
  TransferInventoryJobDataResponse,
  TransferInventoryRequest,
  InventoryTransferItemRemainingResponse,
  WarehouseLocationInventoryItemAPI,
} from '../../types/inventoryTypes';
import { FulfilledServiceAssigneeTaskType } from '../../types';

interface InventoryTransferItemsStateParams {
  taskType?: FulfilledServiceAssigneeTaskType;
  assetVehicleID?: string | number;
  userID?: string | number;
}

interface InventoryTransferItemRemainingParams {
  taskType?: FulfilledServiceAssigneeTaskType;
  warehouseAssetID?: string | number;
}

export function assignTransferInventoryItem(
  payload: InventoryTransferJobRequest,
  shouldSetUILoading: boolean,
): AppThunk<Promise<NormalizeReturn<InventoryTransferJobAPI>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<InventoryTransferJobAPI, InventoryTransferJobRequest>({
        baseUrl: '/inventory/transfer-items/assign',
        payload,
        message: 'Items successfully assigned',
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function transferInventoryItem(
  payload: TransferInventoryRequest,
  shouldSetUILoading: boolean,
): AppThunk<Promise<NormalizeReturn<WarehouseLocationInventoryItemAPI>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<WarehouseLocationInventoryItemAPI, TransferInventoryRequest>({
        baseUrl: '/inventory/transfer-items',
        payload,
        message: 'Items successfully updated',
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function editInventoryItem(
  payload: EditInventoryItemRequest,
  shouldSetUILoading: boolean,
): AppThunk<Promise<NormalizeReturn<WarehouseLocationInventoryItemAPI>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<WarehouseLocationInventoryItemAPI, EditInventoryItemRequest>({
        baseUrl: '/inventory/edit-inventory',
        payload,
        message: 'Item successfully updated',
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getAssignedStockMoveTasks({
  warehouseId,
  transferType,
  taskType,
}: {
  warehouseId: string | number;
  transferType: InventoryTransferTypes;
  taskType?: FulfilledServiceAssigneeTaskType;
}): AppThunk<
  Promise<NormalizeReturn<Array<BasicAssignedTransferJobsIdNamePair>>>
> {
  return async (dispatch) => {
    const { data, error } = await authGet<
      Array<BasicAssignedTransferJobsIdNamePair>
    >([
      'inventory/assigned-stock-move',
      {
        warehouseID: warehouseId,
        taskType,
        transferType,
      },
    ]);
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function scanInventoryItemOriginLocation(
  inventoryTransferId: number,
  payload: ScanInventoryTransferItemRequest,
): AppThunk<Promise<NormalizeReturn<ScanInventoryTransferItemResponse>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<
        ScanInventoryTransferItemResponse,
        ScanInventoryTransferItemRequest
      >({
        baseUrl: `inventory/transfer-items/${inventoryTransferId}/scan-item/origin`,
        payload,
        shouldSetUILoading: true,
        shouldShowErrorMessage: false,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function scanInventoryItemDestinationLocation(
  inventoryTransferId: number,
  payload: ScanInventoryTransferItemRequest,
): AppThunk<Promise<NormalizeReturn<ScanInventoryTransferItemResponse>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<
        ScanInventoryTransferItemResponse,
        ScanInventoryTransferItemRequest
      >({
        baseUrl: `inventory/transfer-items/${inventoryTransferId}/scan-item/destination`,
        payload,
        shouldSetUILoading: true,
        shouldShowErrorMessage: false,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getInventoryTransferItemsState({
  inventoryTransferId,
  filters,
}: {
  inventoryTransferId: number | string;
  filters?: InventoryTransferItemsStateParams;
}): AppThunk<Promise<NormalizeReturn<InventoryTransferItemsStateResponse>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<InventoryTransferItemsStateResponse>([
      `inventory/transfer-items/${inventoryTransferId}/items-stats`,
      filters,
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getVehicleInventoryItemsAsync({
  inventoryTransferId,
  filters,
}: {
  inventoryTransferId: number | string;
  filters: InventoryTransferItemRemainingParams;
}): AppThunk<Promise<NormalizeReturn<InventoryTransferItemRemainingResponse>>> {
  return async (dispatch) => {
    const { data, error } =
      await authGet<InventoryTransferItemRemainingResponse>([
        `inventory/transfer-items/${inventoryTransferId}/remaining-items`,
        filters,
      ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getInventoryTransferDataDetails(
  inventoryTransferId: number,
  originWarehouseLocationID: string | number,
  taskType: FulfilledServiceAssigneeTaskType,
): AppThunk<Promise<NormalizeReturn<TransferInventoryJobDataResponse>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<TransferInventoryJobDataResponse>([
      `inventory/transfer-items/${inventoryTransferId}/transfer-data`,
      { originWarehouseLocationID, taskType },
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getInventoryItemDetails(
  locationIdOrName: string | number,
  customerItemId: string | number,
  params?: InventoryItemDetailsFilterParams,
): AppThunk<Promise<NormalizeReturn<WarehouseLocationInventoryItemAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<WarehouseLocationInventoryItemAPI>([
      `inventory/stock/locations/${locationIdOrName}/items/${customerItemId}/details`,
      params,
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function pauseMoveInventoryJob({
  inventoryTransferId,
  payload,
}: {
  inventoryTransferId: number | string;
  payload: PauseInventoryTransferJobRequest;
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<any, PauseInventoryTransferJobRequest>({
        baseUrl: `inventory/transfer-items/${inventoryTransferId}/pause`,
        payload,
        message: 'Successfully paused job',
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function GetInventoryLocationItemDetails(
  locationIdOrName: string | number,
  warehouseId: string | number,
): AppThunk<Promise<NormalizeReturn<InventoryLocationItemDetailsResponse>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<InventoryLocationItemDetailsResponse>(
      [
        `inventory/stock/locations/${locationIdOrName}/details`,
        { warehouseId },
      ],
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

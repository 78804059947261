import { memo, useCallback } from 'react';
import _get from 'lodash.get';
import { LinkProps, Link as MuiLink } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { AlignType, ObjectWithId } from '../../../types';
import { stableObject } from '../../../lib';

interface Props<TRecord extends ObjectWithId = ObjectWithId> {
  linkProps?: LinkProps;
  record?: TRecord;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
  setUrl?: (record: TRecord) => any;
}

const PageLinkFieldComponent = <TRecord extends ObjectWithId = ObjectWithId>({
  className,
  linkProps: _linkProps,
  source,
  record: _record,
  setUrl,
  ...rest
}: Props<TRecord>) => {
  const { classes, cx } = useStyles();
  const stopPropagation = useCallback((e) => e.stopPropagation(), []);
  const record = (_record || stableObject) as TRecord;
  const linkProps = _linkProps || stableObject;

  const value = _get(record, source);
  if (!value) return <span>--</span>;
  return (
    <MuiLink
      component={Link}
      onClick={stopPropagation}
      className={cx(classes.text, className)}
      underline='none'
      target='_blank'
      to={typeof setUrl === 'function' ? setUrl(record) : undefined}
      {...linkProps}
    >
      {value}
    </MuiLink>
  );
};

export const PageLinkField = memo(PageLinkFieldComponent) as <
  TRecord extends ObjectWithId = ObjectWithId,
>(
  props: Props<TRecord>,
) => React.ReactElement;

const useStyles = makeStyles({ name: { PageLinkFieldComponent } })((theme) => ({
  text: {
    fontSize: 14,
  },
}));

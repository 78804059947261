import { memo, useCallback } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const DateInput = memo(
  /**
   * A material-ui date picker not using formik
   * @param {import("@mui/x-date-pickers/DatePicker").DatePickerProps & inputProps} props
   * @returns
   */
  function ({ onBlur, onChange, value, name, required, ...rest }) {
    const { textFieldProps = {}, ...props } = rest;

    const handleBlur = useCallback(
      (e) => {
        onBlur && onBlur(e);
      },
      [onBlur],
    );

    const handleChange = useCallback(
      (date) => {
        onChange(date ? jsDateToLocalISO8601DateString(date) : null, date);
      },
      [onChange],
    );

    return (
      <DatePicker
        value={dateStringToLocalDate(value) ?? null}
        format='MM/dd/yyyy'
        onChange={handleChange}
        slotProps={{
          textField: {
            name,
            variant: 'standard',
            onBlur: handleBlur,
            required,
            ...textFieldProps,
          },
        }}
        {...props}
      />
    );
  },
);

// Needed because of https://github.com/mui-org/material-ui-pickers/issues/1526
function jsDateToLocalISO8601DateString(date) {
  return [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join('-');
}

function dateStringToLocalDate(s) {
  if (!s) return null;
  return toZonedTime(s);
}

// #region Typedefs
/**
 *  @typedef {object} inputProps
 * @property {import("@mui/material/TextField").TextFieldProps} [textFieldProps]
 * @property {string} [name]
 */
// #endregion

import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './state';

const persistConfig = {
  key: 'app-persist',
  storage,
  whitelist: ['authPersist', 'systemPersist'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// if (import.meta.env.MODE !== 'production' && module.hot) {
//   module.hot.accept('./state/rootReducer', () =>
//     store.replaceReducer(persistedReducer),
//   );
// }

let persistor;
/** Starts the process of persisting and syncing the store. */
export function activateStore() {
  function activatingStore(resolve, reject) {
    function persistStoreCompleted() {
      resolve(store);
    }
    persistor = persistStore(store, null, persistStoreCompleted);
    // crossTabSync(store, persistConfig);
  }
  return new Promise(activatingStore);
}

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

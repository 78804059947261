import { combineReducers } from '@reduxjs/toolkit';
import {
  uiReducer,
  authReducer,
  authPersistReducer,
  systemReducer,
  systemPersistReducer,
  listsReducer,
  ordersReducer,
  CustomersReducer,
  WarehousesReducer,
  CarriersReducer,
  ContactReducer,
  ReceiptsReducer,
} from './features';

const reducers = {
  ui: uiReducer,
  auth: authReducer,
  authPersist: authPersistReducer,
  systemPersist: systemPersistReducer,
  system: systemReducer,
  lists: listsReducer,
  orders: ordersReducer,
  customers: CustomersReducer,
  warehouses: WarehousesReducer,
  carriers: CarriersReducer,
  contacts: ContactReducer,
  receipts: ReceiptsReducer,
};

export const rootReducer = combineReducers({ ...reducers });

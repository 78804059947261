import { useState, Fragment, memo, useEffect, useCallback } from 'react';
import { Button, IconButton, TextFieldProps } from '@mui/material';
import { AddEditCustomViewForm, SearchInput, SelectInput } from '../../common';
import { FilterListIcon } from '../../../assets';
import { Navigation, authGet, listTypes } from '../../../lib';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../../hooks';
import {
  useDispatch,
  useSelector,
  getBasicListCustomViews,
  uiSelectors,
} from '../../../state';
import { FiltersSelect } from './FiltersSelect';
import { FiltersSelectSingle } from './FiltersSelectSingle';
import { FiltersSearch } from './FiltersSearch';
import { FiltersAutocomplete } from './FiltersAutocomplete';
import { FiltersAutocompleteSingle } from './FiltersAutocompleteSingle';
import { FiltersDate } from './FiltersDate';
import { FiltersDateRange } from './FiltersDateRange';
import { FiltersDateTimeRange } from './FiltersDateTimeRange';
import { FiltersSwitch } from './FiltersSwitch';
import { useStyles } from './filters.styles';
import {
  FiltersProps,
  OrderByType,
  SearchInputProps,
  SelectOption,
} from '../../../types';

interface Props {
  defaultSearchProps?: SearchInputProps & TextFieldProps;
  filtersProps?: FiltersProps;
  hasCustomViews?: boolean;
  hasDefaultSearchField?: boolean;
  order?: OrderByType;
  orderBy?: string;
  queryParams: object;
  resource: string;
  searchStyle?: React.CSSProperties;
}

export const ListFiltersActions = memo(
  ({
    defaultSearchProps,
    filtersProps,
    hasCustomViews,
    hasDefaultSearchField,
    order,
    orderBy,
    queryParams,
    resource,
    searchStyle,
  }: Props) => {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const updatePageQueryParams = useUpdatePageQueryParams();
    const [showFilters, setShowFilters] = useState(true);
    const [customViews, setCustomViews] = useState<Array<SelectOption>>([]);
    const [selectedCustomView, setSelectedCustomView] = useState(null);
    const [showCustomViewForm, setShowCustomViewForm] = useState(false);
    const [isNewCustomView, setIsNewCustomView] = useState(false);

    const { 'custom-view-id': customViewId } = useGetQueryParams();
    const viewVersion = useSelector(uiSelectors.viewVersion);

    useEffect(() => {
      (async function () {
        if (listTypes[resource]) {
          const { data } = await dispatch(
            getBasicListCustomViews(listTypes[resource]),
          );
          if (data) {
            setCustomViews(data);
          }
        }
      })();
    }, [dispatch, resource, viewVersion]);

    const getCustomViewByID = useCallback(
      async (id) => {
        const { data } = await authGet([`/system/custom-views/${id}`]);
        if (data) {
          const { order, orderBy, params } = data;
          setSelectedCustomView(data);

          // Reset previous query parameters to null
          const prevQueryParams = { ...queryParams };
          Object.keys(queryParams).forEach((key) => {
            prevQueryParams[key] = null; // Set each query parameter to null
          });

          // Update the URL
          updatePageQueryParams(
            {
              ...prevQueryParams,
              ...params,
              'custom-view-id': id,
              order,
              orderBy,
            },
            true,
          );
        }
      },
      [queryParams, updatePageQueryParams],
    );

    useEffect(() => {
      (async function () {
        if (customViewId) {
          await getCustomViewByID(customViewId);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewVersion]);

    const clearSelectedView = useCallback(() => {
      setSelectedCustomView(null);
      Navigation.go(`${window.location.pathname}`);
      setIsNewCustomView(true);
    }, []);

    const handleSelectCustomView = useCallback(
      async (e) => {
        if (e.target.value) {
          await getCustomViewByID(e.target.value);
          setIsNewCustomView(false);
        } else {
          clearSelectedView();
        }
      },
      [clearSelectedView, getCustomViewByID],
    );

    return (
      <div className={classes.root} style={searchStyle}>
        <div className={classes.firstRow}>
          <div style={{ display: 'flex' }}>
            {hasDefaultSearchField ? (
              <SearchInput {...defaultSearchProps} />
            ) : (
              <span />
            )}
            {hasCustomViews && (
              <div
                className={classes.customView}
                style={
                  hasDefaultSearchField
                    ? {
                        marginLeft: 24,
                      }
                    : {}
                }
              >
                <SelectInput
                  label='My Custom Views'
                  onChange={handleSelectCustomView}
                  options={customViews}
                  value={customViewId || ''}
                  clearable
                  style={{ width: 200 }}
                />
                {selectedCustomView && (
                  <Button
                    color='primary'
                    size='small'
                    style={{ textTransform: 'capitalize', marginLeft: 16 }}
                    onClick={() => setShowCustomViewForm(true)}
                  >
                    Update
                  </Button>
                )}
                <Button
                  color='primary'
                  size='small'
                  style={{ textTransform: 'capitalize', marginLeft: 16 }}
                  onClick={() => {
                    setIsNewCustomView(true);
                    setShowCustomViewForm(true);
                  }}
                >
                  {`Save as ${selectedCustomView ? 'new' : ''}`}
                </Button>
                {showCustomViewForm && (
                  <AddEditCustomViewForm
                    // {...(!isNewCustomView && { editData: selectedCustomView })}
                    editData={!isNewCustomView ? selectedCustomView : null}
                    clearSelectedView={clearSelectedView}
                    queryParams={queryParams}
                    handleClose={() => setShowCustomViewForm(false)}
                    // handleSelectCustomView={handleSelectCustomView}
                    listType={listTypes[resource]}
                    open={showCustomViewForm}
                    order={order}
                    orderBy={orderBy}
                    setIsNewCustomView={setIsNewCustomView}
                  />
                )}
              </div>
            )}
          </div>
          {!!filtersProps && !filtersProps.hideFilterToggle && (
            <IconButton
              onClick={() => setShowFilters(!showFilters)}
              color='primary'
              className={classes.filterBtn}
              size='large'
            >
              <FilterListIcon />
            </IconButton>
          )}
        </div>
        {!!filtersProps && Array.isArray(filtersProps.filters) && (
          <Fragment>
            <div className={cx({ [classes.filterRow]: showFilters })}>
              {filtersProps.filters.map((f, i) => {
                if (f.hide) return null;
                return (
                  <Fragment key={i}>
                    {(!f.filterType || f.filterType === 'select') &&
                      showFilters && (
                        <FiltersSelect
                          label={f.label}
                          filters={f.filter}
                          defaultValue={f.defaultValue}
                          filterSortIds={f.filterSortIds}
                          queryParam={f.queryParam}
                          clearable={f.clearable}
                          className={cx(classes.filterSelect, f.className)}
                          // style={{ marginTop: 15, ...(f.style || {}) }}
                        />
                      )}
                    {f.filterType === 'selectSingle' && showFilters && (
                      <FiltersSelectSingle
                        label={f.label}
                        defaultValue={f.defaultValue}
                        filters={f.filter}
                        filterSortIds={f.filterSortIds}
                        queryParam={f.queryParam}
                        clearable={f.clearable}
                        className={cx(classes.filterSelect, f.className)}
                        // style={{ marginTop: 15, ...(f.style || {}) }}
                      />
                    )}
                    {f.filterType === 'autocomplete' && showFilters && (
                      <FiltersAutocomplete
                        label={f.label}
                        filters={f.filter}
                        filterSortIds={f.filterSortIds}
                        queryParam={f.queryParam}
                        clearable={f.clearable}
                        className={cx(classes.autocomplete, f.className)}
                        // style={{ marginTop: 15, ...(f.style || {}) }}
                      />
                    )}
                    {f.filterType === 'autocompleteSingle' && showFilters && (
                      <FiltersAutocompleteSingle
                        label={f.label}
                        filters={f.filter}
                        filterSortIds={f.filterSortIds}
                        queryParam={f.queryParam}
                        clearable={f.clearable}
                        className={cx(classes.autocomplete, f.className)}
                        // style={{ marginTop: 15, ...(f.style || {}) }}
                      />
                    )}
                    {f.filterType === 'search' && showFilters && (
                      <FiltersSearch
                        label={f.label}
                        queryParam={f.queryParam}
                        clearable={f.clearable}
                        className={cx(classes.filterSelect, f.className)}
                        style={{ marginTop: 15, ...(f.style || {}) }}
                      />
                    )}
                    {f.filterType === 'date' && showFilters && (
                      <FiltersDate
                        label={f.label}
                        queryParam={f.queryParam}
                        defaultValue={f.defaultValue}
                        clearable={f.clearable}
                        className={cx(classes.filterSelect, f.className)}
                        datePickerProps={f.datePickerProps}
                        // style={{ marginTop: 15, ...(f.style || {}) }}
                      />
                    )}
                    {f.filterType === 'dateRange' && showFilters && (
                      <FiltersDateRange
                        label={f.label}
                        queryParams={f.queryParams}
                        defaultValues={f.defaultValues}
                        clearable={f.clearable}
                        className={cx(classes.filterSelect, f.className)}
                        // style={{ marginTop: 15, ...(f.style || {}) }}
                      />
                    )}
                    {f.filterType === 'dateTimeRange' && showFilters && (
                      <FiltersDateTimeRange
                        label={f.label}
                        queryParams={f.queryParams}
                        defaultValues={f.defaultValues}
                        clearable={f.clearable}
                        className={cx(classes.filterSelect, f.className)}
                      />
                    )}
                    {f.filterType === 'switch' && showFilters && (
                      <FiltersSwitch
                        label={f.label}
                        queryParam={f.queryParam}
                        defaultValue={f.defaultValue}
                        clearable={f.clearable}
                        className={cx(classes.filterSelect, f.className)}
                        // style={{ marginTop: 15, ...(f.style || {}) }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          </Fragment>
        )}
      </div>
    );
  },
);

import { memo, useCallback } from 'react';
import { format, isValid, parse } from 'date-fns';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { TextFieldProps } from '@mui/material';
import { useStyles } from '../../../themes/muiTheme';

interface Props extends TimePickerProps<any> {
  textFieldProps: TextFieldProps;
  resultsFormat?: 'date' | 'string';
}

export const TimeInput = memo((props: Props) => {
  const { css } = useStyles();
  const {
    resultsFormat = 'date',
    onChange,
    slotProps = {},
    value,
    ...rest
  } = props;
  const { textFieldProps = {}, ...otherProps } = rest;
  const { name } = textFieldProps;

  const handleChange = useCallback(
    (_value, context) => {
      let value = _value;
      if (resultsFormat === 'string') {
        value = isValid(_value) ? format(_value, 'h:mm a') : '';
      }
      const event = { target: { name, value } };
      onChange && onChange(event, context);
    },
    [name, onChange, resultsFormat],
  );

  return (
    <TimePicker
      value={resultsFormat === 'string' ? dateStringToLocalDate(value) : value}
      format='hh:mm a'
      onChange={handleChange}
      slotProps={{
        textField: {
          variant: 'standard',
          ...textFieldProps,
        },
        openPickerButton: {
          className: css({ color: '#888EA7' }),
          ...((rest.slots as Record<string, any>)?.openPickerButton ?? {}),
        },
        ...slotProps,
      }}
      {...otherProps}
    />
  );
});

function dateStringToLocalDate(s) {
  if (!s) return null;
  // var date = parse(s, 'p', new Date());
  // return isValid(date) ? date : s;
  return parse(s, 'p', new Date());
}

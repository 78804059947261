import { useCallback, useState, useRef, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  CssBaseline,
  AppBar,
  IconButton,
  MenuList,
  ListItem,
  Button,
} from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector, uiSelectors } from '../../state';
import { generateUrl, paths } from '../../lib';
import { IconButtonLink, Dropdown, Notification, PageLoader } from '..';
import {
  AccountCircleIcon,
  DashboardIcon,
  TimerOutlinedIcon,
} from '../../assets';
import { TrackTimePage } from '../../pages/TrackTimePage';

interface Props extends RouteComponentProps {
  handleLogout: () => any;
  children?: React.ReactNode;
}

export function MobileLayout({ handleLogout, children, ...props }: Props) {
  const { classes, css, cx } = useStyles();
  const { location: { pathname } = {} } = props;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const showTimer = useSelector(uiSelectors.showTimer);

  const mobileAnchorRef = useRef<HTMLButtonElement>(null);

  const handleToggleMobileMenu = () => {
    setMobileMenuOpen((prevOpen) => !prevOpen);
  };

  const handleCloseMobileMenu = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (mobileAnchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setMobileMenuOpen(false);
    },
    [],
  );

  const isTimerPage = pathname === `/${paths.TRACK_TIME}`;

  return (
    <Fragment>
      <CssBaseline />
      <div className={cx({ [classes.layout]: !isTimerPage })}>
        {children}
        {/* <div className={classes.offset} /> */}
      </div>
      <AppBar
        position='fixed'
        color='primary'
        className={classes.appBar}
        elevation={0}
      >
        <div className={classes.actionContainer}>
          <div className={classes.actions}>
            {!showTimer || isTimerPage ? (
              <IconButtonLink size='small' to={generateUrl(paths.TRACK_TIME)}>
                <TimerOutlinedIcon className={classes.icon} />
              </IconButtonLink>
            ) : (
              <span />
            )}
            <IconButtonLink size='small' to={generateUrl(paths.MOBILE_HOME)}>
              <DashboardIcon className={classes.icon} />
            </IconButtonLink>
            <IconButton
              ref={mobileAnchorRef}
              onClick={handleToggleMobileMenu}
              // onBlur={!isMobile ? handleClose : undefined}
              size='small'
            >
              <AccountCircleIcon className={classes.icon} />
            </IconButton>
            <Dropdown
              ref={mobileAnchorRef}
              open={mobileMenuOpen}
              handleClose={handleCloseMobileMenu}
              paperProps={{
                elevation: 0,
                className: classes.mobilePaper,
              }}
              popperProps={{
                style: { zIndex: 1 },
                placement: 'bottom',
              }}
            >
              <MenuList
                autoFocusItem={mobileMenuOpen}
                dense
                className={css({ padding: 0 })}
              >
                <ListItem dense>
                  <Button
                    id='log-out'
                    color='primary'
                    variant='contained'
                    onClick={handleLogout}
                    size='small'
                  >
                    log out
                  </Button>
                </ListItem>
              </MenuList>
            </Dropdown>
          </div>
        </div>
      </AppBar>
      {(showTimer || isTimerPage) && <TrackTimePage {...props} />}
      <Notification />
      <PageLoader />
    </Fragment>
  );
}

const useStyles = makeStyles({ name: { MobileLayout } })((theme) => ({
  layout: {
    height: 'calc(100vh - 64px)',
    maxHeight: 'calc(100vh - 64px)',
    overflowY: 'auto',
  },
  appBar: {
    borderRadius: '32px 32px 0 0',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    backgroundColor: '#FFFFFF',
    top: 'auto',
    bottom: 0,
    height: 64,
  },
  offset: {
    minHeight: 64,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80%',
    height: '100%',
  },

  icon: {
    fontSize: 22,
    color: '#43485B',
  },
  mobilePaper: {
    marginTop: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 'initial',
    minHeight: 'initial',
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 'initial',
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
    boxShadow: 'none',
    background: 'inherit',
  },
}));

import { makeStyles } from 'tss-react/mui';
import { AppBar, Link } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { generateUrl, paths } from '../../lib';
import TplLogo from '../../assets/images/3pl-logo.svg';

export function CheckInTabletLayout(props) {
  const { classes } = useStyles();
  return (
    <AppBar
      elevation={0}
      color='transparent'
      position='static'
      className={classes.content}
    >
      <Link to={generateUrl(paths.APPOINTMENT_CHECK_IN)} component={LinkRouter}>
        <TplLogo className={classes.img} />
      </Link>
    </AppBar>
  );
}

const useStyles = makeStyles({ name: { CheckInTabletLayout } })((theme) => ({
  content: {
    alignItems: 'center',
    paddingTop: 40,
  },
  img: {
    height: 40,
    width: 155,
  },
}));

import { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import {
  Chip,
  MenuList,
  ListItem,
  ListItemText,
  IconButton,
  ChipProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isValid } from 'date-fns';
// import { FilterMenu } from '../../';
import { Dropdown } from '../../common';
import { DateInput } from '../..';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { formatDate } from '../../../lib';
import { CloseIcon } from '../../../assets';
import { DatePickerProps } from '@mui/x-date-pickers';
import { MouseChangeEventType } from '../../../types';

interface Props {
  label?: string;
  queryParam?: string;
  // filters: object;
  filterSortIds?: Array<number>;
  chipProps?: ChipProps;
  clearable?: boolean;
  className?: string;
  defaultValue?: Date | string;
  datePickerProps?: DatePickerProps<any>;
}

export const FiltersDate = ({
  label = 'Filters',
  queryParam = 'list-filters',
  chipProps: _chipProps,
  defaultValue,
  datePickerProps: _datePickerProps,
  clearable = true,
}: Props) => {
  const chipProps = _chipProps || ({} as ChipProps);
  const datePickerProps = _datePickerProps || ({} as DatePickerProps<any>);
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: search } = useGetQueryParams();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(search || defaultValue || null);
  const [labelFormat, setLabelFormat] = useState<string | null>();

  useEffect(() => {
    if (search) {
      setLabelFormat(
        formatDate(search, { formatStr: datePickerProps?.format }),
      );
    } else if (isValid(defaultValue)) {
      setLabelFormat(
        formatDate(defaultValue, { formatStr: datePickerProps?.format }),
      );
    } else if (
      typeof defaultValue === 'string' &&
      isValid(new Date(defaultValue))
    ) {
      setLabelFormat(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  const handleClear = useCallback(() => {
    updatePageQueryParams({ [queryParam]: null });
    setValue(null);
    setLabelFormat(null);
    setOpen(false);
  }, [queryParam, updatePageQueryParams]);

  const handleChange = useCallback(
    (dateString, dateValue) => {
      setValue(dateString);

      if (isValid(dateValue)) {
        updatePageQueryParams({ [queryParam]: dateString || null });
        setLabelFormat(
          formatDate(dateValue, { formatStr: datePickerProps?.format }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParam, updatePageQueryParams],
  );

  const {
    className,
    value: _value,
    onChange,
    ...otherDatePickerProps
  } = datePickerProps;

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={!!search ? 'primary' : 'default'}
        label={labelFormat || label}
        className={classes.chip}
        onDelete={clearable && !!search ? handleClear : undefined}
        variant='outlined'
        onClick={handleToggle}
        {...chipProps}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{ style: { zIndex: 1 }, placement: 'bottom-start' }}
      >
        <MenuList autoFocusItem={open}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          <ListItem>
            <DateInput
              name=''
              value={value}
              onChange={handleChange}
              textFieldProps={{
                variant: 'outlined',
                label: '',
                // classes: { root: classes.root },
                className: cx(classes.input, className),
                size: 'small',
              }}
              {...otherDatePickerProps}
            />
          </ListItem>
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 16,
    minHeight: 16,
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 300,
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  input: {
    width: '100%',
    marginTop: 0,
  },
}));

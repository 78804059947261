import { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import {
  Chip,
  MenuList,
  ListItem,
  ListItemText,
  IconButton,
  ChipProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isValid } from 'date-fns';
import { Dropdown, SelectInput } from '../../common';
import { DateInput } from '../..';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { CloseIcon } from '../../../assets';
import { DateRangeDefaultValues, DateRangeQueryParams } from '../../../types';
import { DatePickerProps } from '@mui/x-date-pickers';
import { getDateRanges, stableObject } from '../../../lib';

interface Props {
  label?: string;
  queryParams?: DateRangeQueryParams;
  filterSortIds?: Array<number>;
  chipProps?: ChipProps;
  clearable?: boolean;
  className?: string;
  defaultValues?: DateRangeDefaultValues;
  datePickerProps?: Omit<DatePickerProps<any>, 'value' | 'onChange'>;
}

export const FiltersDateRange = ({
  label = 'Filters',
  queryParams: _queryParams,
  chipProps: _chipProps,
  defaultValues,
  datePickerProps: _datePickerProps,
  clearable = true,
}: Props) => {
  const queryParams = _queryParams || {
    fromParam: 'from',
    toParam: 'to',
    quickDateParam: 'quickDate',
  };
  const chipProps = _chipProps || (stableObject as ChipProps);
  const datePickerProps =
    _datePickerProps || (stableObject as DatePickerProps<any>);
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { fromParam, toParam, quickDateParam } = queryParams;
  const {
    [fromParam]: fromFilter,
    [toParam]: toFilter,
    ...params
  } = useGetQueryParams();

  const quickDateFilter = quickDateParam ? params[quickDateParam] : null;

  const [open, setOpen] = useState(false);
  const [fromValue, setFromValue] = useState(
    fromFilter || defaultValues?.from || null,
  );
  const [toValue, setToValue] = useState(toFilter || defaultValues?.to || null);
  const [quickDateValue, setQuickDateValue] = useState<string | null>(
    quickDateFilter || null,
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (quickDateFilter !== quickDateValue) {
      const range = dateRanges[quickDateFilter];
      if (range) {
        setFromValue(range.from);
        setToValue(range.to);
        setQuickDateValue(quickDateFilter);
        updatePageQueryParams({
          [fromParam]: range.from,
          [toParam]: range.to,
        });
      }
    } else if (isFirstRender.current) {
      isFirstRender.current = false;
      if (defaultValues) {
        setFromValue(defaultValues.from);
        setToValue(defaultValues.to);
      }
    } else {
      setFromValue(fromFilter);
      setToValue(toFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, fromFilter, toFilter, quickDateFilter]);

  // const [labelFormat, setLabelFormat] = useState();

  // useEffect(() => {
  //   if (search) {
  //     setLabelFormat(formatDate(search));
  //   } else if (isValid(defaultValue)) {
  //     setLabelFormat(formatDate(defaultValue));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const anchorRef = useRef<HTMLDivElement>(null);

  const dateRanges = getDateRanges();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback((e) => {
    if (anchorRef.current?.contains(e.currentTarget as Node)) {
      return;
    }
    setOpen(false);
  }, []);

  const handleClear = useCallback(() => {
    const paramsToUpdate = {
      [fromParam]: null,
      [toParam]: null,
    };

    if (quickDateParam) {
      paramsToUpdate[quickDateParam] = null;
    }

    updatePageQueryParams(paramsToUpdate);
    setFromValue(null);
    setToValue(null);
    setQuickDateValue(null);
    // setLabelFormat(null);
    setOpen(false);
  }, [fromParam, quickDateParam, toParam, updatePageQueryParams]);

  const handleChange = useCallback(
    (name) => (dateString, dateValue) => {
      if (isValid(dateValue) || dateString === null) {
        const paramsToUpdate = {};
        if (name === 'from') {
          setFromValue(dateString);
          paramsToUpdate[fromParam] = dateString || null;
          if (quickDateValue) {
            paramsToUpdate[toParam] = toValue || null;
          }
        } else {
          setToValue(dateString);
          paramsToUpdate[toParam] = dateString || null;
          if (quickDateValue) {
            paramsToUpdate[fromParam] = fromValue || null;
          }
        }
        setQuickDateValue(null);

        if (quickDateParam) {
          paramsToUpdate[quickDateParam] = null;
        }

        updatePageQueryParams(paramsToUpdate);
      }
    },
    [
      quickDateParam,
      updatePageQueryParams,
      fromParam,
      quickDateValue,
      toParam,
      toValue,
      fromValue,
    ],
  );

  const handleDateRangeChange = useCallback(
    (e) => {
      setQuickDateValue(e.target.value);
      const range = dateRanges[e.target.value];
      if (range) {
        setFromValue(range.from);
        setToValue(range.to);

        const paramsToUpdate = { [toParam]: range.to, [fromParam]: range.from };
        if (quickDateParam) {
          paramsToUpdate[quickDateParam] = e.target.value;
        }
        updatePageQueryParams(paramsToUpdate);
      }
    },
    [dateRanges, fromParam, quickDateParam, toParam, updatePageQueryParams],
  );

  const { className, ...otherDatePickerProps } = datePickerProps;

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={
          !!(fromValue || toValue || quickDateValue) ? 'primary' : 'default'
        }
        label={label}
        className={classes.chip}
        onDelete={
          clearable && !!(fromValue || toValue || quickDateValue)
            ? handleClear
            : undefined
        }
        variant='outlined'
        onClick={handleToggle}
        {...chipProps}
      />
      <Dropdown
        open={open}
        handleClose={() => {}}
        popperProps={{
          anchorEl: anchorRef.current,
          style: { zIndex: 1 },
          placement: 'bottom-start',
        }}
        paperProps={{ className: classes.paper }}
      >
        <MenuList autoFocusItem={open} onMouseLeave={handleClose}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          <ListItem>
            <div>
              <SelectInput
                label='Quick date selection'
                onChange={handleDateRangeChange}
                options={dateRangeOptions}
                value={quickDateValue}
                clearable
                className={classes.dateInput}
              />
              <div style={{ display: 'flex', marginTop: 16 }}>
                <DateInput
                  value={fromValue}
                  onChange={handleChange('from')}
                  textFieldProps={{
                    variant: 'outlined',
                    label: '',
                    // classes: { root: classes.root },
                    className: cx(classes.input, className),
                    size: 'small',
                    style: { marginRight: 8 },
                  }}
                  {...otherDatePickerProps}
                />
                <DateInput
                  value={toValue}
                  onChange={handleChange('to')}
                  textFieldProps={{
                    variant: 'outlined',
                    label: '',
                    // classes: { root: classes.root },
                    className: cx(classes.input, className),
                    size: 'small',
                  }}
                  {...otherDatePickerProps}
                />
              </div>
            </div>
          </ListItem>
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const dateRangeOptions = [
  { id: 'today', name: 'Today' },
  { id: 'currentWeek', name: 'This week' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'lastWeek', name: 'Last Week' },
  { id: 'pastMonth', name: 'Last Month' },
];

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 600,
  },
  list: {
    width: '100%',
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  input: {
    width: '100%',
    marginTop: 0,
  },
  dateInput: {
    width: 170,
    marginRight: 16,
  },
}));

import { useState, useEffect, useCallback } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { IconButton } from '@mui/material';
import { PlayArrowIcon, StopIcon } from '../assets';
import { AutocompleteInput } from '../components';
import {
  useDispatch,
  getAssignedServicesBasicList,
  startEmployeeTracking,
  stopEmployeeTracking,
  toggleTimer,
} from '../state';
import { useIsMobile, useSetCameraConfig } from '../hooks';
import { arrayToObjByEnumOrId, employeeActivityTypes, paths } from '../lib';
import { useStyles } from './trackTime.styles';

export function TrackTimePage({ location: { pathname } = {} }) {
  const { classes, css, cx } = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [assignedServices, setAssignedServices] = useState([]);
  const [assignedServicesMappings, setAssignedServicesMappings] = useState({});
  const [selectedAssignedService, setSelectedAssignedService] = useState('');
  const [
    selectedAssignedServiceEntityData,
    setSelectedAssignedServiceEntityData,
  ] = useState(null);

  useSetCameraConfig({
    entityId: selectedAssignedServiceEntityData?.entityID,
    serviceType: selectedAssignedServiceEntityData?.serviceType,
    overrideTimerTaskEntity: true,
  });

  const showFullPage = pathname === `/${paths.TRACK_TIME}`;

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(getAssignedServicesBasicList());
      if (data) {
        setAssignedServices(data);
        setAssignedServicesMappings(arrayToObjByEnumOrId(data));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { seconds, minutes, hours, isRunning, start, reset } = useStopwatch();

  const handleTimerClick = useCallback(async () => {
    const payload = {
      activityType: employeeActivityTypes.MANUAL_ACTIVITY,
      details: assignedServicesMappings[selectedAssignedService]?.name,
      fulfilledServiceAssigneeID: selectedAssignedService,
    };

    if (isRunning) {
      reset(undefined, false);
      const { error } = await dispatch(stopEmployeeTracking(payload));
      if (!error) {
        setSelectedAssignedService('');
        setSelectedAssignedServiceEntityData(null);
        dispatch(toggleTimer(false));
      }
    } else {
      start();
      const { error } = await dispatch(startEmployeeTracking(payload));
      if (!error) {
        dispatch(toggleTimer(true));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedServicesMappings, isRunning, selectedAssignedService]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      setSelectedAssignedService(value);
      const foundService = assignedServicesMappings[value];
      if (foundService) {
        setSelectedAssignedServiceEntityData({
          serviceType: foundService.serviceType,
          entityID: foundService.entityID,
        });
      }
    },
    [assignedServicesMappings],
  );

  if (isMobile && !showFullPage) {
    return (
      <div className={classes.globalMobileTimeContainer}>
        <TimeDisplay
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          isGlobalMobile
        />
        <TimerButton
          isRunning={isRunning}
          handleClick={handleTimerClick}
          disabled={!selectedAssignedService}
          isGlobalMobile
        />
      </div>
    );
  }

  return (
    <div
      className={cx(classes.layout, { [classes.globalLayout]: !showFullPage })}
    >
      {showFullPage && <h1 className={classes.title}>Let’s track your time</h1>}
      <div className={classes.content}>
        <div
          className={cx(classes.bar, {
            [css({ padding: 0, paddingLeft: 8 })]: !showFullPage,
          })}
        >
          <AutocompleteInput
            value={selectedAssignedService}
            textFieldProps={{
              name: 'selectedAssignedService',
              placeholder: 'What are you working on?',
              onChange: handleChange,
              // value: selectedAssignedService,
              slotProps: {
                input: {
                  disableUnderline: !isMobile,
                  ...(!isMobile && { endAdornment: null }),
                },
              },
            }}
            autocompleteProps={{
              className: css({ flex: 1 }),
              options: assignedServices,
              disabled: isRunning,
            }}
          />
          {!isMobile && (
            <div className={classes.timeContainer}>
              <TimeDisplay hours={hours} minutes={minutes} seconds={seconds} />
              <TimerButton
                isRunning={isRunning}
                handleClick={handleTimerClick}
                disabled={!selectedAssignedService}
              />
            </div>
          )}
        </div>
        {isMobile && showFullPage && (
          <div className={classes.mobileTimeContainer}>
            <div className={classes.mobileTime}>
              <TimeDisplay hours={hours} minutes={minutes} seconds={seconds} />
            </div>
            <TimerButton
              isRunning={isRunning}
              handleClick={handleTimerClick}
              disabled={!selectedAssignedService}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const TimerButton = ({ isRunning, handleClick, disabled, isGlobalMobile }) => {
  const { classes, cx, css } = useStyles();
  return (
    <IconButton
      onClick={handleClick}
      className={cx(
        classes.timerBtn,
        {
          [classes.globalMobileTimerBtn]: isGlobalMobile,
        },
        isRunning ? classes.stopBtn : classes.startBtn,
      )}
      disabled={disabled}
    >
      {isRunning ? (
        <StopIcon
          className={cx(classes.timerIcon, {
            [css({ fontSize: 10 })]: isGlobalMobile,
          })}
        />
      ) : (
        <PlayArrowIcon className={classes.timerIcon} />
      )}
    </IconButton>
  );
};

function TimeDisplay({ hours, minutes, seconds, isGlobalMobile }) {
  const { classes, cx, css } = useStyles();
  return (
    <span
      className={cx(classes.time, { [css({ fontSize: 20 })]: isGlobalMobile })}
    >
      <span
        className={cx(classes.timeText, {
          [classes.timeTextGlobalMobil]: isGlobalMobile,
        })}
      >
        {formatTime(hours)}
      </span>
      <span
        className={cx(classes.timeText, {
          [classes.timeTickGlobalMobil]: isGlobalMobile,
        })}
      >
        :
      </span>
      <span
        className={cx(classes.timeText, {
          [classes.timeTextGlobalMobil]: isGlobalMobile,
        })}
      >
        {formatTime(minutes)}
      </span>
      <span
        className={cx(classes.timeText, {
          [classes.timeTickGlobalMobil]: isGlobalMobile,
        })}
      >
        :
      </span>
      <span
        className={cx(classes.timeText, {
          [classes.timeTextGlobalMobil]: isGlobalMobile,
        })}
      >
        {formatTime(seconds)}
      </span>
    </span>
  );
}

const formatTime = (time) => {
  return String(time).padStart(2, '0');
};

import { useMemo } from 'react';
import qs from 'query-string';

/**
 * @returns {object} - Returns the query params as an object
 * using query-string `parse`
 */
export function useGetQueryParams() {
  const search = window.location.search;
  return useMemo(() => qs.parse(search, { parseBooleans: true }), [search]);
}

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({
  name: 'sidebar',
})((theme) => ({
  drawerOpen: {
    width: 280,
    borderRight: 'none',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    // overflowX: "hidden",
    width: 65,
    borderRight: 'none',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    overflowX: 'hidden',
  },
  nestedList: {
    margin: '8px 0',
  },
  nested: {
    marginLeft: 60,
    borderLeft: '1px solid #E5E7F5',
  },
  activeRow: {
    background: 'rgba(0, 0, 0, 0.04)',
  },
  nestedSidebarClosed: {
    borderLeft: 'none',
  },
  nestedIcon: {
    fontSize: 18,
    color: '#D5D8EC',
  },
  ListItem: {
    paddingLeft: 20,
  },
  liText: {
    '& span': {
      color: theme.palette.text.primary,
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 500,
    },
  },
  navLink: {
    textDecoration: 'none',
  },
  avatar: {
    backgroundColor: '#604FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  mainContent: {
    marginLeft: 65,
  },
  logout: {
    zIndex: 99999999,
    overflow: 'visible',
  },
  icon: {
    color: '#D5D8EC',
  },
  activeLi: {
    '& svg': {
      color: theme.palette.primary.main,
      // color: theme.palette.primary.main,
    },
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  label: {
    fontSize: '10px !important',
  },
}));

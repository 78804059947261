import {
  useEffect,
  useRef,
  cloneElement,
  Children,
  isValidElement,
  Fragment,
  memo,
  useMemo,
  useCallback,
} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  TableContainer,
  Table,
  TablePagination,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  Toolbar,
  TableProps,
  TableHeadProps,
  TableRowProps,
  TableCellProps,
  TextFieldProps,
} from '@mui/material';
import {
  useDispatch,
  useSelector,
  shallowEqual,
  fetchList,
  updateListParams,
  populateList,
  uiSelectors,
} from '../../state';
import {
  useIsTablet,
  useGetQueryParams,
  useUpdatePageQueryParams,
  useLoader,
} from '../../hooks';
import { FilterListIcon } from '../../assets';
import { getDefaultListParams } from '../../RegisterLists';
import { stableObject } from '../../lib';
import { ListBody } from './ListBody';
import { ListFiltersActions } from './filters/ListFiltersActions';
import { ListLoading } from './ListLoading';
import { ListToolbar } from './ListToolbar';
import { ListAside } from './ListAside';
import { useStyles } from './list.styles';
import type {
  ListActionComponentProps,
  FiltersProps,
  ObjectWithId,
  OrderByType,
  SearchInputProps,
} from '../../types';

export const List = <TRecord extends ObjectWithId = ObjectWithId>({
  body = <ListBody />,
  children,
  rowsPerPage: _rowsPerPage,
  order: _order = 'asc',
  orderBy: _orderBy = 'id',
  tableProps: _tableProps,
  headerProps: _headerProps,
  rowProps,
  cellProps,
  rootClassName,
  paperClassName,
  expand,
  expandOptions,
  filter: _filter,
  aside,
  title,
  bulkActionButtons,
  bulkActionOptions,
  // onToggleItem,
  resource,
  baseUrl,
  rowClick,
  mouseEnter,
  mouseLeave,
  rowStyle,
  rowsPerPageOptions,
  sortable = true,
  hideHeader = false,
  hideFooter = false,
  hideActions = false,
  actions = (
    <ListActions hideHeader={hideHeader} resource={resource} title={title} />
  ),
  listInfo,
  hasCustomViews = false,
  hasDefaultSearchField = true,
  defaultSearchProps,
  searchStyle,
  filtersProps,
  defaultState,
  customViewVersion,
  clearStateOnExit,
  scrollToTop = true,
  shouldUpdateQueryParams = true,
}: ListProps<TRecord>) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const isTablet = useIsTablet();
  const setLoader = useLoader();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const {
    'aside-open': asideOpen,
    rowsPerPage: queryRowsPerPage,
    page: queryPage,
    orderBy: queryOrderBy,
    order: queryOrder,
    ...params
  } = useGetQueryParams();
  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);
  const listLoading = useSelector((state) => state.ui.listLoading);
  const viewVersion = useSelector(
    (state) => customViewVersion ?? state.ui.viewVersion,
  ); // needed to refresh on edit
  const ids = useSelector(
    (state) =>
      (state.lists.resources[resource]?.ids || []) as Array<number | string>,
    shallowEqual,
  );
  const rows = useSelector(
    (state) =>
      (state.lists.resources[resource]?.data || {}) as Record<string, TRecord>,
    shallowEqual,
  );
  const listParams = useSelector(
    (state) => state.lists.resources[resource]?.params || {},
    shallowEqual,
  );
  const selectedIds = useSelector(
    (state) => state.lists.resources[resource]?.params?.selectedIds || [],
    shallowEqual,
  );
  const selectedRows = useSelector(
    (state) => state.lists.resources[resource]?.params?.selectedRows || {},
    shallowEqual,
  );

  const {
    hasList,
    order,
    orderBy,
    page,
    queryParams,
    rowsPerPage,
    numberOfRows,
  } = listParams;

  const tableProps = _tableProps || (stableObject as TableProps);
  const headerProps = _headerProps || (stableObject as TableHeadProps);
  const filter = _filter || (stableObject as FiltersProps);

  const isFirstRender = useRef(true);
  const isFirstFetch = useRef(true);
  const rowsPerPageOptionsString = JSON.stringify(rowsPerPageOptions);

  useEffect(() => {
    if (!rowsPerPage) {
      const defaultRowsPerPage =
        _rowsPerPage || Array.isArray(rowsPerPageOptions)
          ? (rowsPerPageOptions?.[1] ?? 50)
          : 50;

      dispatch(
        updateListParams({
          list: resource,
          params: {
            order: queryOrder || _order,
            orderBy: queryOrderBy || _orderBy,
            rowsPerPage: queryRowsPerPage
              ? +queryRowsPerPage
              : defaultRowsPerPage,
            page: queryPage ? +queryPage - 1 : 0,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _order,
    _orderBy,
    _rowsPerPage,
    dispatch,
    resource,
    rowsPerPage,
    rowsPerPageOptionsString,
  ]);

  // `filter` is causing the useEffect to render too many times.
  // See https://github.com/kentcdodds/use-deep-compare-effect
  useDeepCompareEffect(() => {
    (async function () {
      // reset page if filter changes
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      if (page > 0) {
        dispatch(
          updateListParams({
            list: resource,
            params: {
              page: 0,
            },
          }),
        );
      } else {
        setLoader(true);
        await dispatch(
          fetchList({
            resource,
            baseUrl,
            order,
            orderBy,
            page,
            rowsPerPage,
            filter,
            noPagination: hideFooter,
            sortable,
          }),
        );
        setLoader(false);
      }
    })();
  }, [filter]);

  /**
   * This effect runs only on the first render or when the pagination changes.
   * When the filters change then we use the above effect to reset the page.
   */
  useEffect(() => {
    (async function () {
      setLoader(true);
      const orderValue = sortable ? order : undefined;
      const orderByValue = sortable ? orderBy : undefined;
      const pageValue = !hideFooter && page ? +page + 1 : undefined;
      const rowsPerPageValue = !hideFooter ? rowsPerPage : undefined;
      let defaultFilters: Record<string, any> | undefined;

      // If there are default filters then we add them to the query params to trigger that effect to fetch the list
      if (isFirstFetch.current && !!rowsPerPage) {
        filtersProps?.filters?.forEach((f) => {
          // check if the default filters are already in the query params. If so we don't add them because if we do add them then if they all
          // exist then it won't trigger the effect to fetch the list and since we don't fetch the list here, the page won't load
          if (f.defaultValue && f.queryParam && !params[f.queryParam]) {
            defaultFilters = defaultFilters || {};
            defaultFilters[f.queryParam] = f.defaultValue;
          }
          if (
            f.defaultValues?.from &&
            f.queryParams?.fromParam &&
            !params[f.queryParams.fromParam ?? '']
          ) {
            defaultFilters = defaultFilters || {};
            defaultFilters[f.queryParams.fromParam] = f.defaultValues.from;
          }
          if (
            f.defaultValues?.to &&
            f.queryParams?.toParam &&
            !params[f.queryParams.toParam]
          ) {
            defaultFilters = defaultFilters || {};
            defaultFilters[f.queryParams.toParam] = f.defaultValues.to;
          }
        });

        isFirstFetch.current = false;
      }

      // If there are default filters then we add them to the query params to trigger that effect to fetch the list
      // so we don't call fetchList here
      if (!defaultFilters || !shouldUpdateQueryParams) {
        await dispatch(
          fetchList({
            resource,
            baseUrl,
            order,
            orderBy,
            page,
            rowsPerPage,
            filter,
            noPagination: hideFooter,
            sortable,
          }),
        );
      }
      if (shouldUpdateQueryParams) {
        updatePageQueryParams({
          order: orderValue,
          orderBy: orderByValue,
          page: pageValue,
          rowsPerPage: rowsPerPageValue,
          ...defaultFilters,
        });
      }
      setLoader(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl, order, orderBy, page, resource, rowsPerPage, viewVersion]);

  useEffect(() => {
    return () => {
      clearStateOnExit &&
        dispatch(
          populateList({
            list: resource,
            data: getDefaultListParams(resource),
          }),
        );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearStateOnExit, resource]);

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number,
    ) => {
      dispatch(
        updateListParams({
          list: resource,
          params: { page: newPage },
        }),
      );
      if (shouldUpdateQueryParams) {
        updatePageQueryParams({ page: +newPage + 1 });
      }
      scrollToTop && window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, resource, scrollToTop],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(
        updateListParams({
          list: resource,
          params: { page: 0, rowsPerPage: parseInt(event.target.value, 10) },
        }),
      );
      if (shouldUpdateQueryParams) {
        updatePageQueryParams({
          page: 1,
          rowsPerPage: parseInt(event.target.value, 10),
        });
      }
      scrollToTop && window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, resource, scrollToTop],
  );

  const tableContainerClasses = useMemo(
    () => ({
      root: cx(classes.tableContainer, {
        [classes.tableContainerSidebarOpen]: isSidebarOpen,
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.tableContainer, classes.tableContainerSidebarOpen, isSidebarOpen],
  );

  const tablePaginationClasses = useMemo(
    () => ({
      selectLabel: classes.pagination,
      displayedRows: classes.pagination,
      select: classes.pagination,
    }),
    [classes.pagination],
  );

  const bodyProps = useMemo(
    () => ({
      rowProps,
      cellProps,
      rowClick,
      mouseEnter,
      mouseLeave,
      rows,
      ids,
      hasBulkActions: bulkActionButtons !== false,
      expand,
      expandOptions,
      // onToggleItem,
      resource,
      rowStyle,
      selectedIds,
      selectedRows,
      rowsPerPage,
      page,
      numSelected: selectedIds.length,
      headerProps,
      order,
      orderBy,
      rowCount: ids.length,
      bulkActionButtons,
      bulkActionOptions,
      hideHeader,
    }),
    [
      bulkActionButtons,
      bulkActionOptions,
      cellProps,
      expand,
      expandOptions,
      headerProps,
      hideHeader,
      ids,
      mouseEnter,
      mouseLeave,
      order,
      orderBy,
      page,
      resource,
      rowClick,
      rowProps,
      rowStyle,
      rows,
      rowsPerPage,
      selectedIds,
      selectedRows,
    ],
  );

  return (
    <Fragment>
      <div className={cx(classes.root, rootClassName)}>
        {hasList &&
          !hideActions &&
          isValidElement(actions) &&
          cloneElement(
            actions as React.ReactElement<ListActionComponentProps<TRecord>>,
            {
              resource,
              title,
              classes,
              rows,
              ids,
              selectedIds,
              selectedRows,
              loading: listLoading,
            },
          )}
        {listLoading && !hasList && (
          <ListLoading
            classes={classes}
            className={paperClassName}
            // expand={expand}
            hasBulkActions={bulkActionButtons !== false}
            nbChildren={Children.count(children)}
            size={tableProps.size || isTablet ? 'small' : 'medium'}
          />
        )}
        {hasList && (
          <div className={classes.content}>
            <Paper
              className={cx(
                classes.paper,
                { [classes.asideOpen]: !!asideOpen },
                paperClassName,
              )}
              elevation={0}
            >
              {(hasDefaultSearchField || !!filtersProps) && (
                <ListFiltersActions
                  defaultSearchProps={defaultSearchProps}
                  queryParams={queryParams}
                  order={order}
                  orderBy={orderBy}
                  filtersProps={filtersProps}
                  hasCustomViews={hasCustomViews}
                  hasDefaultSearchField={hasDefaultSearchField}
                  resource={resource}
                  searchStyle={searchStyle}
                />
              )}
              {isValidElement(listInfo) && cloneElement(listInfo)}
              <ListToolbar
                numSelected={selectedIds.length}
                ids={ids}
                rows={rows}
                selectedIds={selectedIds}
                selectedRows={selectedRows}
                bulkActionButtons={bulkActionButtons}
              />
              {ids?.length ? (
                <Fragment>
                  <TableContainer classes={tableContainerClasses}>
                    <Table
                      // className={classes.table}
                      aria-labelledby='tableTitle'
                      aria-label='enhanced table'
                      size={tableProps.size || isTablet ? 'small' : 'medium'}
                      // stickyHeader
                      {...tableProps}
                    >
                      {cloneElement(body, bodyProps, children)}
                    </Table>
                  </TableContainer>
                  {!hideFooter && (
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions || [25, 50, 75]}
                      component='div'
                      count={numberOfRows}
                      rowsPerPage={rowsPerPage || 50}
                      page={page || 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      classes={tablePaginationClasses}
                    />
                  )}
                </Fragment>
              ) : isValidElement(defaultState) ? (
                cloneElement(defaultState)
              ) : (
                <Typography style={{ padding: 24 }}>
                  No results found
                </Typography>
              )}
            </Paper>
            {isValidElement(aside) &&
              cloneElement(<ListAside />, { rows, ids }, aside)}
          </div>
        )}
      </div>
    </Fragment>
  );
};

const ListActions = memo(function ListActions({
  title,
  resource,
  hideHeader,
}: {
  title?: string;
  resource: string;
  hideHeader: boolean;
}) {
  const { classes } = useStyles();
  if (hideHeader) return null;

  return (
    <Toolbar className={classes.actions}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {title || resource}
      </Typography>
      <Tooltip title='Filter list'>
        <IconButton aria-label='filter list' size='large'>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
});

interface ListProps<TRecord extends ObjectWithId = ObjectWithId> {
  body?: React.ReactElement;
  actions?: React.ReactElement;
  listInfo?: React.ReactElement;
  aside?: React.ReactElement;
  bulkActionButtons?: React.ReactElement | false;
  bulkActionOptions?: BulkActionOptions;
  children: React.ReactNode;
  className?: string;
  paperClassName?: string;
  rootClassName?: string;
  rowsPerPage?: number;
  filter?: Record<string, any>;
  order?: OrderByType;
  orderBy?: string;
  shouldUpdateQueryParams?: boolean;
  title?: string;
  resource: string;
  baseUrl?: string;
  rowsPerPageOptions?: number[];
  expand?: React.ReactElement;
  defaultState?: React.ReactElement;
  expandOptions?: ExpandOptionsProps;
  rowClick?: (id: number | string, record: TRecord) => any;
  mouseEnter?: (id: number | string, record: TRecord) => any;
  mouseLeave?: (id: number | string, record: TRecord) => any;
  rowStyle?: (row: TRecord, rowIndex: number) => any;
  tableProps?: TableProps;
  headerProps?: TableHeadProps;
  rowProps?: TableRowProps;
  cellProps?: TableCellProps;
  searchStyle?: React.CSSProperties;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hasCustomViews?: boolean;
  hideActions?: boolean;
  hasDefaultSearchField?: boolean;
  sortable?: boolean;
  clearStateOnExit?: boolean;
  filtersProps?: FiltersProps;
  defaultSearchProps?: SearchInputProps & TextFieldProps;
  customViewVersion?: number;
  scrollToTop?: boolean;
}

interface ExpandOptionsProps {
  keepExpanded?: boolean;
  cellStyle?: React.CSSProperties;
  expandedRowStyle?: React.CSSProperties;
  defaultExpanded?: boolean;
  expandPosition?: 'start' | 'end';
}

interface BulkActionOptions {
  isSticky?: boolean;
}

import { memo } from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useSelector, uiSelectors } from '../../state';
import { useStyles } from './Sidebar.styles';

interface SidebarItemProps {
  /** The URL path for the NavLink component. */
  to: string;
  /** The text to display for the sidebar item. */
  text: string;
  /** The icon to display for the sidebar item. */
  icon?: React.JSXElementConstructor<any>;
  /** Whether the sidebar item is disabled. */
  disabled?: boolean;
  /** A function to determine if the sidebar item is active. */
  isActive?: () => boolean;
  /** Whether the sidebar item is nested. */
  isNested?: boolean;
  /** A function to handle click events. */
  onClick?: () => void;
}

export const SidebarItem = memo(function SidebarItem({
  to,
  text,
  icon: Icon,
  disabled,
  isActive,
  isNested,
  onClick,
}: SidebarItemProps) {
  const { classes, cx } = useStyles();
  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);

  return (
    <NavLink
      to={disabled ? '#' : to}
      className={classes.navLink}
      activeClassName={classes.activeLi}
      // disabled={disabled}
      isActive={isActive}
      onClick={onClick}
    >
      <Tooltip title={isSidebarOpen ? '' : text} placement='right'>
        <ListItemButton
          disabled={disabled}
          className={cx({
            [classes.nested]: isNested,
            [classes.nestedSidebarClosed]: isNested && !isSidebarOpen,
            [classes.ListItem]: !isNested,
            [classes.activeRow]: isActive?.(),
          })}
        >
          {Icon && (
            <ListItemIcon>
              <Icon className={classes.icon} />
            </ListItemIcon>
          )}
          <ListItemText primary={text} className={classes.liText} />
        </ListItemButton>
      </Tooltip>
    </NavLink>
  );
});

import { useCallback, isValidElement, cloneElement, memo } from 'react';
import jsonExport from 'jsonexport/dist';
import _get from 'lodash.get';
import { Button, ButtonProps } from '@mui/material';
import { useNotify, useLoader } from '../../hooks';
import {
  authGet,
  formatDate,
  formatPhone,
  formatCurrency,
  isObject,
  formatNumber,
} from '../../lib';
import { MouseChangeEventType } from '../../types';
import { NormalizeReturn } from '../../state/types';
// import { GetAppIcon } from '../../assets';

export interface HeaderTypes<TRow = Record<string, any>> {
  key: string;
  label: string;
  formatProperty?: string;
  formatFunc?: (row: TRow) => string | null | undefined;
  nestedKey?: string;
  customKey?: string;
  format?: 'date' | 'phone' | 'currency' | 'number';
}

interface Props<TRow = Record<string, any>> {
  path: string;
  queryParams: object;
  headersArray: Array<HeaderTypes<TRow>>;
  fileName: string;
  btnProps?: ButtonProps;
  customBtn?: React.ReactElement<any>;
  dataKey?: string;
}

interface ClonedButton {
  onClick: (e: MouseChangeEventType) => Promise<void>;
}

const ExportButtonComponent = <TRow = Record<string, any>,>({
  path,
  queryParams,
  headersArray,
  fileName,
  btnProps = {},
  customBtn,
  dataKey,
}: Props<TRow>) => {
  // const { classes } = useStyles();
  const notify = useNotify();
  const setLoading = useLoader();

  const fetchData = useCallback(
    async (e: MouseChangeEventType) => {
      setLoading(true);
      const response = await authGet<Promise<NormalizeReturn>>([
        path,
        queryParams,
      ]);
      setLoading(false);
      const { data: _data, error } = response;
      if (error) {
        return notify('Error exporting data', 'error');
      }
      const data = dataKey ? _get(_data, dataKey, []) : _data;
      const dataArray: Array<TRow> = Array.isArray(data) ? data : data.results;
      const { rename, headers, fields } = generateFieldsForExport<TRow>(
        dataArray,
        headersArray,
      );
      jsonExport(
        fields,
        {
          headers,
          rename,
        },
        (err, csv) => {
          downloadCSV(csv, fileName);
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataKey, fileName, headersArray, notify, path, queryParams],
  );

  // TODO disable if no list items
  return isValidElement(customBtn) ? (
    cloneElement(customBtn as React.ReactElement<ClonedButton>, {
      onClick: fetchData,
    })
  ) : (
    <Button
      // className={classes.btn}
      color='primary'
      size='large'
      variant='contained'
      // startIcon={<GetAppIcon />}
      onClick={fetchData}
      {...btnProps}
    >
      Export
    </Button>
  );
};

export const ExportButton = memo(ExportButtonComponent) as <TRow>(
  props: Props<TRow>,
) => React.ReactElement;

// code from https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/export/downloadCSV.ts
function downloadCSV(csv, filename) {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    (window.navigator as any).msSaveOrOpenBlob(blob, `${filename}.csv`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}.csv`);
    fakeLink.click();
  }
}

function generateFieldsForExport<TRow = Record<string, any>>(
  data: Array<TRow>,
  headersArray: Array<HeaderTypes<TRow>>,
) {
  if (!headersArray) return { fields: data };
  const rename: Array<string> = [];
  const headers: Array<string> = [];
  const fields: Array<TRow> = [];
  // get headers and rename fields
  headersArray.forEach((h) => {
    rename.push(h.label);
    // if it's an object use the nested key as the key
    headers.push(h.customKey || h.nestedKey || h.key);
  });
  // get data values thats in the headersArray
  data.forEach((row) => {
    const valuesObj = headersArray.reduce<TRow>((acc, cur) => {
      let key = cur.key;
      if (Object.prototype.hasOwnProperty.call(row, key)) {
        let value = row[key];
        if (Array.isArray(value)) {
          if (cur.formatProperty) {
            value = value.map((v) => v[cur.formatProperty!]).join(',');
          } else {
            value = value.join(',');
          }
        } else if (isObject(value)) {
          value = _get(value, cur.nestedKey);
          key = cur.nestedKey || '';
        }
        if (cur.format) {
          value = formatters[cur.format]?.(value) || value;
        }
        if (typeof cur.formatFunc === 'function') {
          value = cur.formatFunc(row);
        }
        acc[cur.customKey || key] = value;
      }
      return acc;
    }, {} as TRow);
    fields.push(valuesObj);
  });
  return { rename, headers, fields };
}

const formatters = {
  date: formatDate,
  phone: formatPhone,
  currency: formatCurrency,
  number: formatNumber,
};

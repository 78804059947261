import {
  Children,
  isValidElement,
  memo,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import _get from 'lodash.get';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  TableCellBaseProps,
} from '@mui/material';
import { useStyles } from './list.styles';

import { BulkActionProps, InputChangeEventType } from '../../types';

interface Props {
  headerProps?: TableCellBaseProps;
  bulkActionOptions?: BulkActionProps;
  numSelected?: number;
  order?: 'asc' | 'desc';
  orderBy: string;
  onSelectAllClick?: (e: InputChangeEventType, checked: boolean) => any;
  onRequestSort?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string,
  ) => any;
  rowCount?: number;
  fields?: ReactNode;
  hasBulkActions?: boolean;
  hasExpand?: boolean;
}

const ListHeadComponent = ({
  headerProps: _headerProps,
  bulkActionOptions,
  numSelected = 0,
  order,
  orderBy,
  onSelectAllClick,
  onRequestSort,
  rowCount = 0,
  fields,
  hasBulkActions,
  hasExpand,
}: Props) => {
  const { classes, cx } = useStyles();
  const [headerProps] = useState(_headerProps || {});

  const createSortHandler = useCallback(
    (property: string) =>
      (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        onRequestSort?.(event, property);
      },
    [onRequestSort],
  );

  const checkboxClasses = useMemo(
    () => ({
      root: classes.checkbox,
      checked: classes.checkboxChecked,
    }),
    [classes.checkbox, classes.checkboxChecked],
  );

  return (
    <TableHead>
      <TableRow>
        {hasBulkActions && (
          <TableCell
            padding='checkbox'
            className={cx(classes.tableHead, {
              [classes.stickyRightColumn]: bulkActionOptions?.isSticky,
            })}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              classes={checkboxClasses}
              size='small'
            />
          </TableCell>
        )}
        {Children.map(fields, (field, index) => {
          return isValidElement(field) ? (
            <TableCell
              key={_get(field, 'props.source', index)}
              align={_get(field, 'props.align', 'left')}
              padding={field.props.disablePadding ? 'none' : 'normal'}
              sortDirection={
                orderBy === field.props.sortBy || orderBy === field.props.source
                  ? order
                  : false
              }
              className={cx(
                classes.tableHead,
                {
                  [classes.stickyRightColumn]:
                    field.props.stickyPosition === 'right',
                  [classes.stickyLeftColumn]:
                    field.props.stickyPosition === 'left',
                },
                field.props.headerClassName,
              )}
              {...headerProps}
            >
              {field.props.sortable !== false &&
              (field.props.sortBy || field.props.source) ? (
                <TableSortLabel
                  active={
                    orderBy === field.props.sortBy ||
                    orderBy === field.props.source
                  }
                  direction={
                    orderBy === field.props.sortBy ||
                    orderBy === field.props.source
                      ? order
                      : 'asc'
                  }
                  onClick={createSortHandler(
                    field.props.sortBy || field.props.source,
                  )}
                >
                  {field.props.label}
                  {orderBy === field.props.sortBy ||
                  orderBy === field.props.source ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{field.props.label}</span>
              )}
            </TableCell>
          ) : null;
        })}
        {hasExpand && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

export const ListHead = memo(ListHeadComponent) as (
  props: Props,
) => React.ReactElement;

import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { muiTheme } from './themes/muiTheme';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
// import { CacheProvider } from '@emotion/react';
// import { TssCacheProvider } from 'tss-react';
import { jwtDecode } from 'jwt-decode';
import { setAuthRequestToken, Navigation, ErrorSentry, setUser } from './lib';
import { useGetUsersData } from './hooks';
import {
  getInternationalShippingTypes,
  getSystemSettings,
  useDispatch,
} from './state';
import { store, persistor, activateStore } from './store';
import Routes from './Routes';
import RegisterLists from './RegisterLists';

/**
 * Decoded authorization data.
 * @type {{userId:string, roles?:string[]}}
 */
let authTokenData;

/**
 * Loads the token from storage or redux and registers it with `AuthRequest`.
 */
function preloadAuthToken() {
  if (authTokenData) {
    return;
  }
  const state = store.getState();
  const {
    authPersist: {
      data: {
        expiration,
        token,
        firstName,
        lastName,
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress':
          email,
      } = {},
    } = {},
  } = state;
  if (token) {
    authTokenData = jwtDecode(token);

    setUser({
      email,
      name: `${firstName} ${lastName}`,
    });
    setAuthRequestToken(token, expiration ?? '');
  }
}

function FetchSystemData() {
  const dispatch = useDispatch();
  // TODO un-comment after all data is final
  // const systemSettings = useSelector(
  //   (state) => state.systemPersist.systemSettings,
  //   shallowEqual,
  // );
  const { token } = useGetUsersData();
  useEffect(() => {
    if (token) {
      // if (!Object.keys(systemSettings).length) {
      dispatch(getSystemSettings());
      dispatch(getInternationalShippingTypes());
      // }
    }
  }, [dispatch, token]);

  return null;
}

function render() {
  Navigation.init();
  createRoot(document.getElementById('root')!).render(
    // <React.StrictMode>
    <ErrorSentry>
      <ThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FetchSystemData />
              <RegisterLists />
              <Routes />
            </LocalizationProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </ErrorSentry>,
    // </React.StrictMode>,
  );
}

/**
 * Startup function.
 *
 * NOTE: The `store` already comes preloaded with data since `state/store.js`
 * does that.
 */
async function main() {
  //https://vitejs.dev/guide/build.html#load-error-handling
  window.addEventListener('vite:preloadError', (event) => {
    window.location.reload(); //  refresh the page
  });

  await activateStore();
  preloadAuthToken();
  render();
}

main();

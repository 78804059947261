import { Theme, useMediaQuery } from '@mui/material';

/**
 * Returns `true` if the screen is sized for TV screens. Depends on theme.
 * @param {boolean} [noSsr] Set to true to calculate on first render
 */
export function useIsTvScreen(noSsr?: boolean) {
  return useMediaQuery((theme: Theme) => theme.breakpoints.up('tv'), {
    noSsr,
  });
}

/**
 * Returns `true` if the screen is sized for desktop. Depends on theme.
 * @param {boolean} [noSsr] Set to true to calculate on first render
 */
export function useIsLargeDesktop(noSsr?: boolean) {
  return useMediaQuery((theme: Theme) => theme.breakpoints.only('lg'), {
    noSsr,
  });
}

/**
 * Returns `true` if the screen is sized for small desktop. Depends on theme.
 * @param {boolean} [noSsr] Set to true to calculate on first render
 */
export function useIsSmallDesktop(noSsr?: boolean) {
  return useMediaQuery((theme: Theme) => theme.breakpoints.only('md'), {
    noSsr,
  });
}

/**
 * Returns `true` if the screen is sized for small desktop. Depends on theme.
 * @param {boolean} [noSsr] Set to true to calculate on first render
 */
export function useIsMediumDesktop(noSsr?: boolean) {
  return useMediaQuery(
    (theme: Theme) => theme.breakpoints.between('laptop', 'desktop'),
    {
      noSsr,
    },
  );
}

/**
 * Returns `true` if the screen is sized for tablet. Depends on theme.
 * @param {boolean} [noSsr] Set to true to calculate on first render
 */
export function useIsTablet(noSsr?: boolean) {
  return useMediaQuery(
    (theme: Theme) => theme.breakpoints.between('tablet', 'laptop'),
    {
      noSsr,
    },
  );
}

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme.
 * @param {boolean} [noSsr] Set to true to calculate on first render
 */
export function useIsMobile(noSsr?: boolean) {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'), {
    noSsr,
  });
}

import { makeStyles } from 'tss-react/mui';
import { Button, ButtonProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

type ButtonLinkProps = LinkProps & ButtonProps;

export function SecondaryButton(props: ButtonProps) {
  const { classes, cx } = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Button
      className={cx(classes.btn, className)}
      variant='contained'
      size='large'
      disableElevation
      {...rest}
    >
      {children}
    </Button>
  );
}

export function SecondaryButtonLink(props: ButtonLinkProps) {
  const { classes, cx } = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Button
      className={cx(classes.btn, className)}
      variant='contained'
      size='large'
      disableElevation
      component={Link}
      {...rest}
    >
      {children}
    </Button>
  );
}

const useStyles = makeStyles({ name: { SecondaryButton } })((theme) => ({
  btn: {
    backgroundColor: '#F5F6FE',
    '&:hover': {
      backgroundColor: '#F5F6FE',
    },
    color: theme.palette.text.primary,
  },
}));

import { useCallback, useEffect, useState, memo } from 'react';
import _get from 'lodash.get';
import {
  TextField,
  Autocomplete,
  Divider,
  Checkbox,
  Paper,
  Box,
  FormControlLabel,
} from '@mui/material';
import {
  isObject,
  getValueFromAutocompleteInput,
  arrayToObjByEnumOrId,
} from '../../../lib';
import { useStyles } from './inputs.styles';

/**
 *
 * @param {props} props
 * @returns
 */
export function AutocompleteInput({
  formikProps,
  hasSelectAllOption = false,
  textFieldProps: _textFieldProps,
  value,
  validationKey,
  ...otherProps
}) {
  const {
    // setValues: formikSetValues,
    handleBlur: formikHandleBlur,
    values: formikValues,
    handleChange: formikHandleChange,
    touched,
    errors,
  } = formikProps || {};

  const { onChange, onBlur, ...textFieldProps } = _textFieldProps;
  const { name } = textFieldProps;

  const handleSelect = useCallback(
    async (e, newInputValue) => {
      const value = getValueFromAutocompleteInput(newInputValue);
      const standardEventObj = { target: { name, value } };
      formikHandleChange && formikHandleChange(standardEventObj);
      onChange && onChange(standardEventObj);
    },
    [formikHandleChange, name, onChange],
  );

  const handleBlur = useCallback(
    (e) => {
      formikHandleBlur && formikHandleBlur(e);
      onBlur && onBlur(e);
    },
    [formikHandleBlur, onBlur],
  );

  // On blur touched is set on the name but on submit it's set on the nested object so we need to check both
  const isTouched = !!(_get(touched, name) || _get(touched, validationKey));
  const errorText = _get(errors, validationKey ?? name);

  return (
    <RenderAutocompleteInput
      onBlur={handleBlur}
      handleSelect={handleSelect}
      error={isTouched && !!errorText}
      hasSelectAllOption={hasSelectAllOption}
      helperText={isTouched ? errorText : undefined}
      textFieldProps={textFieldProps}
      value={value ?? formikValues?.[name]}
      {...otherProps}
    />
  );
}

const RenderAutocompleteInput = memo(function RenderAutocompleteInput({
  handleSelect,
  onBlur,
  error,
  hasSelectAllOption,
  helperText,
  value,
  textFieldProps: _textFieldProps,
  autocompleteProps: _autocompleteProps,
}) {
  const { classes, cx } = useStyles();
  const [optionsObj, setOptionsObj] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const { className, style, options, ...autocompleteProps } =
    _autocompleteProps;
  const {
    name,
    label,
    autoComplete,
    slotProps: _slotProps,
    ...textFieldProps
  } = _textFieldProps;
  const {
    input = {},
    htmlInput = {},
    inputLabel = {},
    ...slotProps
  } = _slotProps || {};

  useEffect(() => {
    if (options.length) {
      setOptionsObj(arrayToObjByEnumOrId(options));
    }
  }, [options]);

  const handleToggleSelectAll = () => {
    setSelectAll((cur) => {
      handleSelect(null, !cur ? options : []);
      return !cur;
    });
  };

  const onChange = (e, value, reason) => {
    if (hasSelectAllOption) {
      if (reason === 'clear' || reason === 'removeOption') {
        setSelectAll(false);
      }
      if (reason === 'selectOption' && value.length === options.length) {
        setSelectAll(true);
      }
    }
    handleSelect(e, value);
  };

  return (
    <Autocomplete
      key={Object.keys(optionsObj).length}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      isOptionEqualToValue={(option, value) => {
        if (!value) return true; // needed to disable mui warning that empty doesn't match any options

        if (isObject(value)) {
          return option.id === value.id;
        } else if (optionsObj[value]) {
          return option.id + '' === optionsObj[value].id + '';
        }
        return option.id === value;
      }}
      getOptionLabel={(option) => {
        if (isObject(option)) {
          return option.name ?? '';
        } else if (optionsObj[option]) {
          return optionsObj[option].name ?? '';
        }
        return option?.toString() ?? '';
      }}
      options={options}
      className={cx(classes.inputRoot, className)}
      style={style}
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        return (
          <Paper {...restPaperProps}>
            {hasSelectAllOption && (
              <>
                <Box
                  onMouseDown={(e) => e.preventDefault()} // prevent blur
                  pl={1.5}
                  py={0.5}
                >
                  <FormControlLabel
                    onClick={(e) => {
                      e.preventDefault(); // prevent blur
                      handleToggleSelectAll();
                    }}
                    label='Select all'
                    control={
                      <Checkbox id='select-all-checkbox' checked={selectAll} />
                    }
                  />
                </Box>
                <Divider />
              </>
            )}
            {children}
          </Paper>
        );
      }}
      renderInput={(params) => {
        // We need this to disable browser autocomplete https://stackoverflow.com/a/58922317
        params.inputProps.autoComplete = autoComplete ?? 'off';
        return (
          <TextField
            variant='standard'
            {...params}
            label={label}
            name={name}
            error={error}
            helperText={helperText}
            slotProps={{
              input: {
                ...params.InputProps,
                // endAdornment: params.InputProps.endAdornment,
                ...input,
              },
              htmlInput: {
                ...params.inputProps,
                'data-lpignore': true,
                ...htmlInput,
              },

              inputLabel: { className: classes.label, ...inputLabel },
              ...slotProps,
            }}
            {...textFieldProps}
          />
        );
      }}
      {...autocompleteProps}
    />
  );
});

/**
 * @typedef {object} props
 * @property {any} [value]
 * @property {string} [name]
 * @property {object} [formikProps]
 * @property {Omit<import("@mui/material/Autocomplete").AutocompleteProps, "renderInput">} autocompleteProps
 * @property {import("@mui/material/TextField").TextFieldProps} textFieldProps
 * @property {string|Array<string>} [validationKey] For validation of nested object
 */

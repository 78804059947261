import { memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import {
  Button,
  IconButton,
  ButtonProps,
  IconButtonProps,
} from '@mui/material';

type ButtonLinkProps = LinkProps & ButtonProps;

type IconButtonLinkProps = LinkProps & IconButtonProps;

export const ButtonLink = memo(({ children, ...rest }: ButtonLinkProps) => (
  <Button component={Link} {...rest}>
    {children}
  </Button>
));

export const IconButtonLink = memo(
  ({ children, ...rest }: IconButtonLinkProps) => (
    <IconButton component={Link} {...rest}>
      {children}
    </IconButton>
  ),
);

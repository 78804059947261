import { useState, useCallback, memo, useMemo } from 'react';
import {
  ClickAwayListener,
  Tooltip,
  Fade,
  IconButton,
  Badge,
  Popper,
  Grow,
  Paper,
  MenuList,
  ListItem,
  ListItemText,
  Button,
  ListItemIcon,
} from '@mui/material';
import { makeStyles } from '../../themes';
import { NotificationIcon, OpenInNewIcon, ClearAllIcon } from '../../assets';
import {
  useSelector,
  useDispatch,
  removeAlert,
  removeAllAlerts,
  uiSelectors,
} from '../../state';
import { IconButtonLink, ListDeleteIcon } from '../../components';

export const Alerts = memo(() => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const alerts = useSelector(uiSelectors.alerts);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleToggle = useCallback((event) => {
    setAnchorEl((cur) => (cur ? null : event.currentTarget));
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRemoveAllAlerts = useCallback(() => {
    dispatch(removeAllAlerts());
  }, [dispatch]);

  const handleRemoveAlert = useCallback(
    (id) => {
      dispatch(removeAlert({ id }));
    },
    [dispatch],
  );

  const alertKeys = Object.keys(alerts);
  const hasNotifications = !!alertKeys.length;

  const hasErrors = useMemo(
    () => alertKeys.some((k) => alerts[k]?.hasError),
    [alertKeys, alerts],
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div style={{ display: 'flex', height: '100%' }}>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          placement='right'
          title='Notifications'
        >
          <IconButton
            className={classes.margin}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
            size='small'
          >
            <Badge
              badgeContent={alertKeys.length}
              max={99}
              color={hasErrors ? 'error' : 'primary'}
            >
              <NotificationIcon className={classes.icon} />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          disablePortal
          placement='top-start'
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
            // {
            //   name: 'preventOverflow',
            //   enabled: true,
            //   options: {
            //     es,
            //   },
            // },
          ]}
          popperOptions={{
            positionFixed: true,
          }}
        >
          {({ TransitionProps, placement }) => {
            return (
              <Grow
                {...TransitionProps}
                id='menu-list-grow'
                style={{
                  transformOrigin: 'bottom left',
                  // maxWidth: 480,
                }}
              >
                <Paper elevation={0} className={classes.paper}>
                  <MenuList
                    style={{
                      minWidth: hasNotifications ? 480 : 0,
                    }}
                    className={classes.menu}
                    disablePadding
                  >
                    {hasNotifications ? (
                      alertKeys.map((a) => {
                        const { title, message, pageLink } = alerts[a];
                        return (
                          <ListItem
                            key={a}
                            onClick={handleClose}
                            divider
                            alignItems='flex-start'
                            secondaryAction={
                              <IconButton
                                aria-label='Delete'
                                onClick={() => handleRemoveAlert(a)}
                                size='large'
                              >
                                <ListDeleteIcon />
                              </IconButton>
                            }
                          >
                            {!!pageLink && (
                              <ListItemIcon>
                                <IconButtonLink to={pageLink}>
                                  <OpenInNewIcon color='primary' />
                                </IconButtonLink>
                              </ListItemIcon>
                            )}
                            <ListItemText
                              primary={title || undefined}
                              secondary={message}
                              className={classes.message}
                              // classes={{
                              //   primary: classes.message,
                              //   secondary: classes.message,
                              // }}
                            />
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem onClick={handleClose}>
                        <ListItemText
                          secondary='You have no notifications.'
                          secondaryTypographyProps={{
                            align: 'center',
                          }}
                        />
                      </ListItem>
                    )}
                    {hasNotifications && (
                      <ListItem dense>
                        <Button
                          color='secondary'
                          style={{ textTransform: 'none' }}
                          onClick={handleRemoveAllAlerts}
                          startIcon={<ClearAllIcon />}
                        >
                          Clear all
                        </Button>
                      </ListItem>
                    )}
                  </MenuList>
                </Paper>
              </Grow>
            );
          }}
        </Popper>
      </div>
    </ClickAwayListener>
  );
});

const useStyles = makeStyles({ name: { Alerts } })((theme) => ({
  margin: {
    margin: 0,
  },
  icon: {
    color: '#D5D8EC',
  },
  menu: {
    maxHeight: 600,
    overflowY: 'auto',
    // hide scroll bar https://stackoverflow.com/a/49278385
    scrollbarWidth: 'none' /* Firefox */,
    msOverflowStyle: 'none' /* Internet Explorer 10+ */,
    /* WebKit (Chrome) */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxWidth: 480,
  },
  paper: {
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
  },
  message: {
    whiteSpace: 'normal',
    // maxWidth: 400,
    // overflowWrap: 'break-word',
  },
}));

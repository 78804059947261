import { memo } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useStyles } from './fields.styles';

import type { AlignType, ObjectWithId } from '../../../types';
import { stableObject } from '../../../lib';

interface FunctionFieldProps<TRecord extends ObjectWithId = ObjectWithId> {
  typographyProps?: TypographyProps;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string | React.ReactNode;
  record?: TRecord;
  render: (
    record: TRecord,
    source: string,
    index: number,
    rowKey: string,
  ) => any;
  align?: AlignType;
  sortBy?: string;
  sortable?: boolean;
  stickyPosition?: 'right' | 'left';
  disablePadding?: boolean;
  index?: number;
  rowKey?: string;
}

const FunctionFieldComponent = <TRecord extends ObjectWithId = ObjectWithId>({
  className,
  typographyProps: _typographyProps,
  source,
  record: _record,
  render = () => {},
  index = 0,
  rowKey = '',
  ...rest
}: FunctionFieldProps<TRecord>) => {
  const { classes, cx } = useStyles();
  const record = (_record || stableObject) as TRecord;
  const typographyProps = _typographyProps || stableObject;

  return (
    <Typography
      component='span'
      variant='body2'
      className={cx(classes.field, className)}
      {...typographyProps}
    >
      {render(record, source, index, rowKey)}
    </Typography>
  );
};

export const FunctionField = memo(FunctionFieldComponent) as <
  TRecord extends ObjectWithId = ObjectWithId,
>(
  props: FunctionFieldProps<TRecord>,
) => React.ReactElement;

import { ReactElement } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Loader } from '../../ui';
import { useIsMobile } from '../../../hooks';
import { MobileModalForm } from '../../../themes/mobile/MobileModalForm';

interface Props {
  open: boolean;
  text?: string;
  title?: string | ReactElement;
  handleClose: () => void;
  handleDelete: () => void;
  btnText?: string;
  cancelText?: string;
  submitting?: boolean;
  submitBtnClassName?: string;
}

export function DeleteConfirmation(props: Props) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <RenderMobileDeleteConfirmation {...props} />
  ) : (
    <RenderDesktopDeleteConfirmation {...props} />
  );
}

function RenderDesktopDeleteConfirmation({
  open,
  text = '',
  title,
  handleClose,
  handleDelete,
  btnText = 'Delete',
  cancelText = 'Cancel',
  submitting,
  submitBtnClassName,
}: Props) {
  const { classes, cx } = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color='textPrimary'>{text}</DialogContentText>
        {submitting && <Loader />}
        <DialogActions>
          <Button
            onClick={handleDelete}
            className={cx(classes.deleteBtn, submitBtnClassName)}
            disabled={submitting}
          >
            {btnText}
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={classes.cancelBtn}
            onClick={handleClose}
            disableElevation
            disabled={submitting}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

function RenderMobileDeleteConfirmation({
  open,
  text = '',
  title,
  handleClose,
  handleDelete,
  btnText = 'Delete',
  cancelText = 'Cancel',
  submitting,
  submitBtnClassName,
}: Props) {
  return (
    <MobileModalForm
      open={open}
      handleClose={handleClose}
      callback={handleDelete}
      disableSubmit={submitting}
      btnText={btnText}
      cancelBtnText={cancelText}
      submitting={submitting}
      title={title}
      submitBtnClassName={submitBtnClassName}
    >
      <DialogContentText color='textPrimary'>{text}</DialogContentText>
    </MobileModalForm>
  );
}

const useStyles = makeStyles({ name: { DeleteConfirmation } })((theme) => ({
  cancelBtn: {
    backgroundColor: '#F5F6FE',
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#F5F6FE',
    },
    color: theme.palette.text.primary,
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

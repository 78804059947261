import { useCallback, useState, useEffect } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput, SwitchInput, SelectInput } from '../inputs';
import {
  useDispatch,
  addResource,
  getCustomerById,
  useSelector,
  systemSelectors,
  invalidateCustomerCache,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { InfoMessage } from '../../../components';
import {
  convertNullFieldsToEmptyString,
  Navigation,
  generateUrl,
  paths,
  pathParams,
  nestedPages,
} from '../../../lib';
import { ContactAddressFields } from './ContactFields';

const initialState = {
  companyName: '',
  firstName: '',
  lastName: '',
  isActive: true,
  isUser: false,
  isTruckingJobContact: false,
  isBillingRecipient: false,
  isOrderEmailRecipient: false,
  isReceiptEmailRecipient: false,
  email: '',
  officePhone: '',
  fax: '',
  address1: '',
  address2: '',
  city: '',
  stateID: '',
  zip: '',
  countryID: '',
  jobTitle: '',
  createQbCustomer: false,
};

export function InternalCustomerForm({
  open,
  handleClose,
  customerId,
  noRedirect,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  const jobTitleTypes = useSelector(systemSelectors.jobTitleTypes);

  const handleSubmit = useCallback(
    async (values) => {
      const payload = generatePayload(values);
      setSubmitting(true);
      const { data } = await dispatch(
        addResource({
          baseUrl: `/customers`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (data) {
        dispatch(invalidateCustomerCache('basicCarrierCompanies'));
        if (customerId || noRedirect) {
          refresh();
          handleClose();
        } else {
          const { id } = data;
          Navigation.redirect(
            generateUrl(paths.CUSTOMER_PAGES, {
              [pathParams.CUSTOMER_ID]: id,
              [pathParams.NESTED_PAGE]: nestedPages.CUSTOMER_CONTRACT,
            }),
          );
        }
      }
    },
    [customerId, dispatch, handleClose, refresh, noRedirect],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  useEffect(() => {
    (async function () {
      if (customerId) {
        const { data } = await dispatch(getCustomerById(customerId));
        if (data) {
          setState((cur) => ({ ...cur, ...getCustomerEditData(data) }));
        }
      }
    })();
  }, [customerId, dispatch]);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          customerId ? 'Edit' : 'Create'
        } customer`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <InfoMessage message='The primary contact must be a user to have access to the system.' />
        <Grid container spacing={5} rowSpacing={2} style={{ marginTop: 16 }}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='companyName'
              label='Company name'
              formikProps={formik}
              autoComplete='new-password'
              required
            />
          </Grid>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <SwitchInput
              name='isActive'
              label='Active customer'
              formikProps={formik}
            />
          </Grid>
        </Grid>
        <div className={classes.sectionTitle}>Contact info</div>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            ex={12}
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='firstName'
              label='First name'
              formikProps={formik}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            ex={12}
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='lastName'
              label='Last name'
              formikProps={formik}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='email'
              label='Email'
              autoComplete='new-password'
              formikProps={formik}
              required={formik.values.isUser}
            />
          </Grid>
          <Grid
            ex={12}
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='fax'
              label='Fax'
              formikProps={formik}
              format='phone'
            />
          </Grid>
          <Grid
            ex={12}
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='officePhone'
              label='Phone number'
              formikProps={formik}
              autoComplete='new-password'
              format='phone'
            />
          </Grid>
          <Grid
            ex={12}
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='jobTitle'
              label='Title'
              options={jobTitleTypes}
              formikProps={formik}
              required={formik.values.isUser}
            />
          </Grid>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <SwitchInput
              name='isUser'
              label='Contact is user'
              formikProps={formik}
            />
          </Grid>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <SwitchInput
              name='isTruckingJobContact'
              label='Trucking job contact'
              formikProps={formik}
            />
          </Grid>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <SwitchInput
              name='isBillingRecipient'
              label='Receives invoice emails'
              formikProps={formik}
            />
          </Grid>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <SwitchInput
              name='isOrderEmailRecipient'
              label='Receives order emails'
              formikProps={formik}
            />
          </Grid>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <SwitchInput
              name='isReceiptEmailRecipient'
              label='Receives receipt emails'
              formikProps={formik}
            />
          </Grid>
        </Grid>
        <div className={classes.sectionTitle}>Address</div>
        <Grid container spacing={5} rowSpacing={2}>
          <ContactAddressFields
            formikProps={formik}
            inputClassName={classes.input}
            addressFieldName='address1'
            idUpperCase
          />
        </Grid>
        <div className={classes.sectionTitle}>QuickBooks</div>
        <Grid
          ex={12}
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <Grid
            ex={12}
            size={{
              sm: 12,
            }}
          >
            <SwitchInput
              name='createQbCustomer'
              label='Create in QuickBooks'
              formikProps={formik}
            />
          </Grid>
        </Grid>
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  companyName: string().nullable().required('Required'),
  email: string('Enter your email')
    .email('Enter a valid email')
    .nullable()
    .when('isUser', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  jobTitle: string()
    .nullable()
    .when('isUser', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
});

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
  input: {
    width: '100%',
  },
}));

function getCustomerEditData(data) {
  const { contacts, name, shippingSelectionPreference, companyName, ...rest } =
    data;

  let rv = {
    ...convertNullFieldsToEmptyString(rest),
    companyName: companyName ?? name ?? '',
  };

  const primaryContact = contacts?.filter((c) => c.isPrimary)?.[0];

  if (primaryContact) {
    const converted = convertNullFieldsToEmptyString(primaryContact);
    rv.email = converted.email;
    rv.officePhone = converted.officePhone;
    rv.fax = converted.fax;
    rv.address1 = converted.address1;
    rv.address2 = converted.address2;
    rv.city = converted.city;
    rv.stateID = converted.stateID;
    rv.zip = converted.zip;
    rv.countryID = converted.countryID;
    rv.countryName = converted.countryName;
    rv.firstName = converted.firstName;
    rv.lastName = converted.lastName;
    rv.jobTitle = converted.jobTitle;
    rv.contactID = converted.contactID;
    rv.isUser = converted.isUser ?? false;
    rv.isTruckingJobContact = converted.isTruckingJobContact ?? false;
    rv.isBillingRecipient = converted.isBillingRecipient ?? false;
    rv.isOrderEmailRecipient = converted.isOrderEmailRecipient ?? false;
    rv.isReceiptEmailRecipient = converted.isReceiptEmailRecipient ?? false;
    rv.primaryUserId = converted.userID;
    rv.primaryPersonId = converted.personID;
  }

  return rv;
}

function generatePayload(state) {
  const {
    firstName,
    lastName,
    address1,
    city,
    stateID,
    zip,
    countryID,
    email,
    officePhone,
    fax,
    jobTitle,
    additionalContacts,
    contactID,
    primaryUserId,
    primaryPersonId,
    isUser,
    isTruckingJobContact,
    isBillingRecipient,
    isOrderEmailRecipient,
    isReceiptEmailRecipient,
    ...rest
  } = state;

  const primaryContact = {
    customerID: state.id,
    // contactID: state.primaryContactId,
    firstName,
    lastName,
    email,
    officePhone,
    fax,
    address1,
    city,
    stateID,
    zip,
    countryID,
    jobTitle,
    isPrimary: true,
    contactID,
    userID: primaryUserId || null,
    personID: primaryPersonId || null,
    isUser,
    isTruckingJobContact,
    isBillingRecipient,
    isOrderEmailRecipient,
    isReceiptEmailRecipient,
  };

  return { ...rest, contacts: [primaryContact] };
}

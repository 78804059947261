import { authGet } from '../../lib';
import { setUILoading, showError } from '../features';
import type { IdNamePair } from '../../types/globalTypes';
import type { NormalizeReturn, AppThunk } from '../types';
import { addResource } from './resource';
import {
  AppointmentAPI,
  AppointmentAssigneeUnloadResponse,
  AppointmentCheckInDetailType,
  AppointmentInitialNote,
  AppointmentScheduleRecords,
  AppointmentSlotParams,
  AppointmentSlotTimes,
  UnloadAppointmentPayload,
  CancelAppointmentPayload,
  AppointmentShipmentJobsAPI,
  AppointmentShipmentJobLoadAPI,
  AppointmentCheckInInitialNote,
  AppointmentProcessingActivityType,
  AppointmentRecord,
  AppointmentLoadInfo,
  AssignManagerRequest,
  VerifyAppointmentReceiptsRequest,
  AppointmentReceiptsResponseType,
  AppointmentActivityTimelineResponse,
} from '../../types';

export function driverCheckIn(
  warehouseId: string | number,
  payload: Record<string, any>,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/warehouses/${warehouseId}/appointments/driver-check-in`,
        payload,
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function cancelDriverCheckIn({
  warehouseId,
  checkInId,
  payload,
}: {
  warehouseId: string | number;
  checkInId: string | number;
  payload: Record<string, any>;
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/warehouses/${warehouseId}/appointments/driver-check-in/${checkInId}/cancel`,
        payload,
        shouldSetUILoading: true,
        message: 'Successfully checked out the driver',
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function completeAppointment({
  warehouseId,
  appointmentId,
  payload,
}: {
  warehouseId: string | number;
  appointmentId: string | number;
  payload: Record<string, any>;
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/warehouses/${warehouseId}/appointments/${appointmentId}/complete`,
        payload,
        shouldSetUILoading: true,
        message: 'Successfully checked out the driver',
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getAppointmentCheckInInitialNote(
  warehouseId: number | string,
  checkInId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentCheckInInitialNote>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentCheckInInitialNote>(
      `warehouses/${warehouseId}/appointments/driver-check-in/${checkInId}/initial-note`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentById(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentCheckInDetailType>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentCheckInDetailType>(
      `warehouses/${warehouseId}/appointments/${appointmentId}`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function linkAppointmentsToCheckIn({
  warehouseId,
  checkInId,
  payload = {},
}: {
  warehouseId: number | string;
  checkInId: number | string;
  payload: Record<string, any>;
}): AppThunk<Promise<NormalizeReturn<AppointmentCheckInDetailType>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `warehouses/${warehouseId}/appointments/driver-check-in/${checkInId}/link-appointments`,
        payload,
        message: 'Changes saved',
        shouldShowErrorMessage: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function unlinkCheckInToAppointment({
  warehouseId,
  checkInId,
  payload = {},
}: {
  warehouseId: number | string;
  checkInId: number | string;
  payload: Record<string, any>;
}): AppThunk<Promise<NormalizeReturn<AppointmentCheckInDetailType>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `warehouses/${warehouseId}/appointments/driver-check-in/${checkInId}/unlink-appointments`,
        payload,
        message: 'Changes saved',
        shouldShowErrorMessage: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function upsertAppointment(
  warehouseId: number | string,
  payload: object,
  shouldSetUILoading: boolean,
): AppThunk<Promise<NormalizeReturn<AppointmentAPI>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<AppointmentAPI>({
        baseUrl: `warehouses/${warehouseId}/appointments`,
        payload,
        message: 'Changes saved',
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function addServicesToAppointment(
  warehouseId: number | string,
  appointmentId: number | string,
  payload: AppointmentScheduleRecords,
  shouldSetUILoading: boolean,
): AppThunk<Promise<NormalizeReturn<AppointmentAPI>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<AppointmentAPI, AppointmentScheduleRecords>({
        baseUrl: `warehouses/${warehouseId}/appointments/${appointmentId}/schedule-services`,
        payload,
        message: 'Changes saved',
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function removeRecordsFromAppointment(
  warehouseId: number | string,
  appointmentId: number | string,
  payload: AppointmentScheduleRecords,
  shouldSetUILoading: boolean,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<AppointmentAPI, AppointmentScheduleRecords>({
        baseUrl: `warehouses/${warehouseId}/appointments/${appointmentId}/remove-services`,
        payload,
        message: 'Changes saved',
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getAppointmentForUpsertById(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentAPI>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/upsert`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentInitialNote(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentInitialNote>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentInitialNote>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/initial-note`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentSlotTimes(
  warehouseId: number | string,
  params: AppointmentSlotParams,
): AppThunk<Promise<NormalizeReturn<AppointmentSlotTimes>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentSlotTimes>([
      `warehouses/${warehouseId}/appointments/appointments-slots`,
      params,
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentLoadInfo(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentLoadInfo>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentLoadInfo>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/load-info`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentRecords(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<Array<AppointmentRecord>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<AppointmentRecord>>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/services`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentJobsDetails(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentShipmentJobsAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentShipmentJobsAPI>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/shipment-job/details`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentJobsLoad(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentShipmentJobLoadAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentShipmentJobLoadAPI>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/shipment-jobs/allocated-pallets`,
    );
    if (error) {
      // don't show an error if there are no jobs allocated
      if (error.code !== 'NoJobs') {
        dispatch(
          showError({
            message: error.message || error.title || 'An error occurred',
          }),
        );
      }
      return { error };
    }
    return { data };
  };
}

export function allocateAppointmentJobLoad({
  warehouseId,
  appointmentId,
  payload,
}: {
  warehouseId: number | string;
  appointmentId: number | string;
  payload: AppointmentShipmentJobsAPI;
}): AppThunk<Promise<NormalizeReturn<AppointmentShipmentJobsAPI>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<any, AppointmentShipmentJobsAPI>({
        baseUrl: `warehouses/${warehouseId}/appointments/${appointmentId}/shipment-job/allocate-pallets`,
        payload,
        message: 'Changes saved',
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentAssignedTasksBasic(
  warehouseId: number | string,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>(
      `warehouses/${warehouseId}/appointments/assigned-users/basic`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentProcessingTimeline(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentProcessingActivityType>>> {
  return async (dispatch) => {
    dispatch(setUILoading(true));
    const { data, error } = await authGet<AppointmentProcessingActivityType>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/processing-timeline`,
    );
    dispatch(setUILoading(false));
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getAppointmentTimeline(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<
  Promise<NormalizeReturn<Array<AppointmentActivityTimelineResponse>>>
> {
  return async (dispatch) => {
    const { data, error } = await authGet<
      Array<AppointmentActivityTimelineResponse>
    >(`warehouses/${warehouseId}/appointments/${appointmentId}/timeline`);
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

export function getAppointmentUnloadDoorData(
  warehouseId: number | string,
  doorNumber: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentAssigneeUnloadResponse>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentAssigneeUnloadResponse>(
      `warehouses/${warehouseId}/appointments/load-activity/${doorNumber}`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function unloadAppointment({
  warehouseId,
  appointmentId,
  payload,
}: {
  warehouseId: number | string;
  appointmentId: number | string;
  payload: UnloadAppointmentPayload;
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<any, UnloadAppointmentPayload>({
        baseUrl: `warehouses/${warehouseId}/appointments/${appointmentId}/load-activity/unload`,
        payload,
        message: 'Changes saved',
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function cancelAppointment({
  warehouseId,
  appointmentId,
  payload,
}: {
  warehouseId: number | string;
  appointmentId: number | string;
  payload: CancelAppointmentPayload;
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<any, CancelAppointmentPayload>({
        baseUrl: `warehouses/${warehouseId}/appointments/${appointmentId}/cancel`,
        payload,
        message: 'Changes saved',
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function assignAppointmentManager({
  warehouseId,
  appointmentId,
  payload,
}: {
  warehouseId: number | string;
  appointmentId: number | string;
  payload: AssignManagerRequest;
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<any, AssignManagerRequest>({
        baseUrl: `warehouses/${warehouseId}/appointments/${appointmentId}/assign-manager`,
        payload,
        message: 'Successfully assigned manager',
        shouldSetUILoading: false,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function verifyAppointmentReceipts({
  warehouseId,
  appointmentId,
  payload,
}: {
  warehouseId: number | string;
  appointmentId: number | string;
  payload: VerifyAppointmentReceiptsRequest;
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<any, VerifyAppointmentReceiptsRequest>({
        baseUrl: `warehouses/${warehouseId}/appointments/${appointmentId}/verify-receipts`,
        payload,
        message: 'Successfully verified receipts',
        shouldSetUILoading: false,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getAppointmentReceipts(
  warehouseId: number | string,
  appointmentId: number | string,
): AppThunk<Promise<NormalizeReturn<AppointmentReceiptsResponseType>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<AppointmentReceiptsResponseType>(
      `warehouses/${warehouseId}/appointments/${appointmentId}/receipts`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

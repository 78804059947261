import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { object, date } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { RadioInput, FormikDateTimeInput } from '../inputs';
import { useDispatch, scheduleDrayageJobAppointments } from '../../../state';
import { useRefresh, useNotify } from '../../../hooks';
import { getDateFromApiResponse, getUtcDateFromTimezone } from '../../../lib';

const updateModes = {
  DELIVERY_ORDER_LEVEL: 'deliveryOrderLevel',
  JOB_LEVEL: 'jobLevel',
};

const initialState = {
  updateMode: updateModes.JOB_LEVEL,
  deliveryFrom: null,
  deliveryTo: null,
  pickupFrom: null,
  pickupTo: null,
};

export function DrayageAppointmentForm({
  open,
  handleClose,
  jobIds, // for batch edit
  jobId, // for single edit
  deliveryOrderNumber, // for DO level edit
  deliveryFrom,
  deliveryTo,
  pickupFrom,
  pickupTo,
  timezone,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const [state, setState] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setState((cur) => ({
      ...cur,
      deliveryFrom: deliveryFrom
        ? getDateFromApiResponse({
            date: deliveryFrom,
            convertToLocalTimezone: true,
            useTime: true,
            timeZone: timezone,
          })
        : null,
      deliveryTo: deliveryTo
        ? getDateFromApiResponse({
            date: deliveryTo,
            convertToLocalTimezone: true,
            useTime: true,
            timeZone: timezone,
          })
        : null,
      pickupFrom: pickupFrom
        ? getDateFromApiResponse({
            date: pickupFrom,
            convertToLocalTimezone: true,
            useTime: true,
            timeZone: timezone,
          })
        : null,
      pickupTo: pickupTo
        ? getDateFromApiResponse({
            date: pickupTo,
            convertToLocalTimezone: true,
            useTime: true,
            timeZone: timezone,
          })
        : null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { updateMode } = values;
      const payload = {
        deliveryFrom: getUtcDateFromTimezone({
          date: values.deliveryFrom,
          timezone,
        }),
        deliveryTo: getUtcDateFromTimezone({
          date: values.deliveryTo,
          timezone,
        }),
        pickupFrom: getUtcDateFromTimezone({
          date: values.pickupFrom,
          timezone,
        }),
        pickupTo: getUtcDateFromTimezone({ date: values.pickupTo, timezone }),
      };
      if (updateMode === updateModes.DELIVERY_ORDER_LEVEL) {
        payload.deliveryOrderNumber = deliveryOrderNumber;
      } else {
        // for batch level
        if (jobIds) {
          payload.drayageJobIDs = jobIds;
        } else {
          payload.drayageJobIDs = [jobId];
        }
      }

      const { data } = await dispatch(
        scheduleDrayageJobAppointments({
          payload,
        }),
      );
      setSubmitting(false);
      if (data) {
        if (
          Array.isArray(data.validationMessages) &&
          data.validationMessages.length
        ) {
          const msgDisplay = data.validationMessages.map((m, i) => (
            <Fragment key={i}>
              <span>{m.message}</span>
              <br />
            </Fragment>
          ));
          notify(msgDisplay, 'error');
        } else {
          notify('Changes saved');
        }
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryOrderNumber, jobId, jobIds, timezone],
  );

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Appointments</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <div>
        {/* Only show options if not batch edit */}
        {!jobIds && (
          <RadioInput
            formikProps={formik}
            name='updateMode'
            radiosRow
            size='small'
            title='Update mode'
            titleClassName={classes.labelTitle}
            radios={[
              {
                label: `Job #${jobId}`,
                value: updateModes.JOB_LEVEL,
                labelClassName: classes.radioLabel,
              },
              {
                label: `Delivery order #${deliveryOrderNumber}`,
                value: updateModes.DELIVERY_ORDER_LEVEL,
                labelClassName: classes.radioLabel,
              },
            ]}
          />
        )}
        <FormikDateTimeInput
          name='pickupFrom'
          formikProps={formik}
          label='Pickup from'
          timezone={timezone}
          className={classes.input}
        />
        <FormikDateTimeInput
          name='pickupTo'
          formikProps={formik}
          label='Pickup to'
          timezone={timezone}
          className={classes.input}
        />
        <FormikDateTimeInput
          name='deliveryFrom'
          formikProps={formik}
          label='Delivery from'
          timezone={timezone}
          className={classes.input}
        />
        <FormikDateTimeInput
          name='deliveryTo'
          formikProps={formik}
          label='Delivery to'
          timezone={timezone}
          className={classes.input}
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
  labelTitle: {
    fontWeight: 'normal',
  },
  radioLabel: {
    fontSize: 12,
  },
}));

const schema = object().shape({
  deliveryFrom: date().typeError('Invalid Date').nullable(),
  deliveryTo: date().typeError('Invalid Date').nullable(),
  pickupFrom: date().typeError('Invalid Date').nullable(),
  pickupTo: date().typeError('Invalid Date').nullable(),
});

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { makeStyles } from 'tss-react/mui';
import { Toolbar, Typography } from '@mui/material';
import { formatDate, getFiltersFromDropdownList } from '../../lib';
import { useSelector, systemSelectors } from '../../state';
import { FilterParams, SystemServiceType } from '../../types';
import {
  DataGrid,
  TextField,
  FunctionField,
  AdjustEmployeeTrackingForm,
  ListEditIcon,
  DateField,
  List,
  ExportButton,
  NotesField,
} from '..';
import { useGetQueryParams, useGetUsersData } from '../../hooks';
import { format } from 'date-fns';

interface Props {
  baseUrl: string;
  serviceType: SystemServiceType;
  activityTotals?: React.ReactNode;
}

interface AdjustDataProps {
  trackingID: number;
  startTime: Date;
  endTime: Date;
}

export const ServiceEmployeeActivities = ({
  baseUrl,
  serviceType,
  activityTotals,
}: Props) => {
  const { classes, css } = useStyles();
  const { isAdmin } = useGetUsersData();
  const {
    activity,
    details,
    employee: employeeName,
    note,
    'end-time-from': endTimeFrom,
    'end-time-to': endTimeTo,
    'start-time-from': startTimeFrom,
    'start-time-to': startTimeTo,
    'user-type': userTypes,
  } = useGetQueryParams();

  const userTypesOptions = useSelector(systemSelectors.userTypes);
  const employeeActivityTypes = useSelector(
    systemSelectors.employeeActivityTypes,
  );

  const [showAdjustModal, setShowAdjustModal] = useState(false);
  const [adjustData, setAdjustData] =
    useStateWithCallbackInstant<AdjustDataProps | null>(null, (data) =>
      setShowAdjustModal(!!data),
    );
  const [allUserTypes, setAllUserTypes] = useState({});
  const [allActivityTypes, setAllActivityTypes] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (userTypesOptions.length) {
      setAllUserTypes(getFiltersFromDropdownList(userTypesOptions));
    }
  }, [userTypesOptions]);

  useEffect(() => {
    if (employeeActivityTypes.length) {
      setAllActivityTypes(getFiltersFromDropdownList(employeeActivityTypes));
    }
  }, [employeeActivityTypes]);

  const handleEditClick = useCallback(
    (record) => {
      const { trackingID, startTime, endTime } = record;

      setAdjustData({
        startTime,
        endTime,
        trackingID,
      });
    },
    [setAdjustData],
  );

  const apiFilters = useMemo(
    () => ({
      activityTypes: activity,
      details,
      employeeName,
      endTimeFrom,
      endTimeTo,
      note,
      startTimeFrom,
      startTimeTo,
      userTypes,
    }),
    [
      activity,
      details,
      employeeName,
      endTimeFrom,
      endTimeTo,
      note,
      startTimeFrom,
      startTimeTo,
      userTypes,
    ],
  );

  const uiFilters: FilterParams[] = useMemo(() => {
    const rv: FilterParams[] = [
      {
        queryParam: 'employee',
        label: 'Employee',
        filterType: 'search',
      },
      {
        filter: allUserTypes,
        queryParam: 'user-type',
        label: 'User type',
      },
      {
        filter: allActivityTypes,
        queryParam: 'activity',
        label: 'Activity',
      },
      {
        queryParams: { fromParam: 'start-time-from', toParam: 'start-time-to' },
        label: 'Start time',
        filterType: 'dateTimeRange',
      },
      {
        queryParams: { fromParam: 'end-time-from', toParam: 'end-time-to' },
        label: 'End time',
        filterType: 'dateTimeRange',
      },
      {
        queryParam: 'note',
        label: 'Note',
        filterType: 'search',
      },
      {
        queryParam: 'details',
        label: 'Details',
        filterType: 'search',
      },
    ];
    return rv;
  }, [allActivityTypes, allUserTypes]);

  if (!isAdmin) return null;

  return (
    <div className={css({ marginTop: 40 })}>
      <List
        actions={
          <ListActions
            apiFilters={apiFilters}
            baseUrl={baseUrl}
            serviceType={serviceType}
            activityTotals={activityTotals}
          />
        }
        baseUrl={`${baseUrl}/employee-activities`}
        resource={`${serviceType.toLowerCase()}-employee-activities`}
        filter={apiFilters}
        bulkActionButtons={false}
        orderBy='id'
        order='asc'
        clearStateOnExit
        filtersProps={{
          hasMultipleFilters: true,
          filters: uiFilters,
          hideFilterToggle: true,
        }}
        paperClassName={classes.paper}
        rootClassName={classes.list}
        hasDefaultSearchField={false}
        defaultState={
          <Typography style={{ padding: 24 }}>No results found</Typography>
        }
      >
        <DataGrid>
          <TextField
            source='actionDoneByName'
            label='Employee'
            sortable={false}
          />
          <TextField
            source='userTypeDisplay'
            label='User type'
            sortable={false}
            headerClassName={classes.noWrap}
          />
          <TextField
            source='activityTypeDisplay'
            label='Activity'
            sortable={false}
          />
          <DateField
            source='startTime'
            label='Start'
            sortable={false}
            format='Pp zzz'
            useTime
            headerClassName={classes.noWrap}
          />
          <DateField
            source='endTime'
            label='End'
            sortable={false}
            format='Pp zzz'
            useTime
            headerClassName={classes.noWrap}
          />
          <NotesField
            source='note'
            label='Note'
            sortable={false}
            maxCharacterLength={30}
          />
          <TextField source='details' label='Details' sortable={false} />
          <FunctionField
            source=''
            label=''
            sortable={false}
            render={(record) => {
              const { trackingID } = record;
              return trackingID ? (
                <ListEditIcon onClick={() => handleEditClick(record)} />
              ) : null;
            }}
          />
        </DataGrid>
      </List>
      {showAdjustModal && !!adjustData?.trackingID && (
        <AdjustEmployeeTrackingForm
          adjustActivityFormType='Employee'
          open={showAdjustModal}
          handleClose={() => setAdjustData(null)}
          startTime={adjustData?.startTime}
          endTime={adjustData?.endTime}
          trackingId={adjustData.trackingID}
        />
      )}
    </div>
  );
};

function ListActions({ activityTotals, apiFilters, baseUrl, serviceType }) {
  const { classes, css, cx } = useStyles();

  const exportParams = generateExportParams({ baseUrl, serviceType });

  return (
    <Toolbar
      className={cx(classes.actions, css({ alignItems: 'flex-start' }))}
      disableGutters
    >
      <div>
        <div className={classes.title}>Employee Activities</div>
        {activityTotals}
      </div>
      <ExportButton
        headersArray={exportParams.exportFields}
        path={exportParams.exportURLPath}
        queryParams={{
          ...(exportParams.exportURLQuery || {}),
          ...apiFilters,
        }}
        fileName={exportParams.exportFileName}
      />
    </Toolbar>
  );
}

const exportFields = [
  { key: 'actionDoneByName', label: 'Employee' },
  { key: 'activityTypeDisplay', label: 'Activity' },
  {
    key: 'startTime',
    label: 'Start',
    formatFunc: (row) =>
      formatDate(row.startTime, {
        formatStr: 'Pp zzz',
        useTime: true,
        timeZone: row.warehouseTimezoneID,
      }),
  },
  {
    key: 'endTime',
    label: 'End',
    formatFunc: (row) =>
      formatDate(row.endTime, {
        formatStr: 'Pp zzz',
        useTime: true,
        timeZone: row.warehouseTimezoneID,
      }),
  },
  { key: 'note', label: 'Note' },
  { key: 'details', label: 'Details' },
];

const generateExportParams = ({ baseUrl, serviceType }) => {
  return {
    exportURLPath: `${baseUrl}/employee-activities`,
    exportURLQuery: { IsExport: true },
    exportFields: exportFields,
    exportFileName: `${serviceType}_employee_tracking_${format(
      new Date(),
      'Pp',
    )}`,
  };
};

const useStyles = makeStyles({ name: { ServiceEmployeeActivities } })(
  (theme) => ({
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 0,
      marginBottom: 24,
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    list: {
      padding: 0,
    },
    paper: {
      boxShadow: 'none',
      marginBottom: -40,
    },
  }),
);
